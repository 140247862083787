/**
 * ContactForm component.
 * @module components/Contact/ContactForm
 */
import { useCallback, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Toolbar, Toast } from '@plone/volto/components';
import { getBaseUrl, BodyClass, usePrevious } from '@plone/volto/helpers';
import { useClient } from '@plone/volto/hooks';
import { contact } from '../../actions';


const messages = defineMessages({
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  contactForm: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  fullName: {
    id: 'Name and surnames',
    defaultMessage: 'Name and surnames',
  },
  fullNamePlaceholder: {
    id: 'Write here your name and surnames',
    defaultMessage: 'Write here your name and surnames',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'Write here your email',
    defaultMessage: 'Write here your email',
  },
  entity: {
    id: 'Entity / Company',
    defaultMessage: 'Entity / Company',
  },
  entityPlaceholder: {
    id: 'Write here the name',
    defaultMessage: 'Write here the name',
  },
  jobPosition: {
    id: 'Job Position',
    defaultMessage: 'Job Position',
  },
  jobPositionPlaceholder: {
    id: 'Write here your job Position',
    defaultMessage: 'Write here your job Position',
  },
  solutions: {
    id: 'Solutions',
    defaultMessage: 'Solutions',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messagePlaceholder: {
    id: 'Write here your message',
    defaultMessage: 'Write here your message',
  },
  acceptCommercial: {
    id: 'I agree to receive commercial information, including by mail',
    defaultMessage: 'I agree to receive commercial information, including by mail',
  },
  privacyPolicy: {
    id: 'I have read and accept the privacy policy',
    defaultMessage: 'I have read and accept the privacy policy',
  },
  recaptcha: {
    id: 'Recaptcha',
    defaultMessage: 'Recaptcha',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  administracioElectronica: {
    id: 'Administració Electrònica',
    defaultMessage: 'Administració Electrònica',
  },
  audioVideoActa: {
    id: 'Audio Video Acta',
    defaultMessage: 'Audio Video Acta',
  },
  comunicacioDigital: {
    id: 'Comunicació Digital',
    defaultMessage: 'Comunicació Digital',
  },
  governObert: {
    id: 'Govern Obert',
    defaultMessage: 'Govern Obert',
  },
  errorRecaptcha: {
    id: 'Error Recaptcha',
    defaultMessage: 'Error Recaptcha',
  },
});

const useContact = () => {
  const loading = useSelector((state) => state.contact.loading);
  const loaded = useSelector((state) => state.contact.loaded);
  const error = useSelector((state) => state.contact.error);

  return { loading, loaded, error };
};

const ContactForm = (props) => {
  const { content } = props;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const language = useSelector((state) => state.intl.locale);

  const getPrivacyPolicyLink = function (content) {
    let privacyPolicyLink = `
      <p>
        Consultar
        <a href='/es/aviso-legal' target="_blank">Política de privacidad <span class="visually-hidden">(se abrirá nueva ventana)</span></a>.
      </p>
    `
    if (language === 'ca')
      privacyPolicyLink = `
        <p>
          Consultar
          <a href='/ca/avis-legal' target="_blank">Política de privacitat <span class="visually-hidden">(s'obrirà nova finestra)</span></a>.
        </p>
      `
    return privacyPolicyLink
  }
  const getPrivacyText = function (content) {
    let privacyText = `
      <p>
        Finalidades: Responder a sus solicitudes y remitirle información comercial
        de nuestros productos y servicios, incluso por medios electrónicos.
        Legitimación: Consentimiento del interesado. Destinatarios: No están 
        previstas cesiones de datos. Derechos: Puede retirar su consentimiento 
        en cualquier momento, así como acceder, rectificar, suprimir sus datos 
        y demás derechos en
        <a href="mailto:ecityclic@delegado-datos.com">ecityclic@delegado-datos.com</a>.
        Información Adicional:
        Puede ampliar la información en el enlace de 
        <a href='/es/aviso-legal'>Aviso legal</a>.
      </p>
    `
    if (language === 'ca')
      privacyText = `
        <p>
          Finalitats: Respondre a les vostres sol·licituds i remetre-li informació
          comercial dels nostres productes i serveis, fins i tot per mitjans electrònics.
          Legitimació: Consentiment de linteressat. Destinataris: No estan previstes
          cessions de dades. Drets: Podeu retirar el vostre consentiment en qualsevol moment,
          així com accedir, rectificar, suprimir les vostres dades i altres drets a
          <a href="mailto:ecityclic@delegado-datos.com">ecityclic@delegado-datos.com</a>.
          Informació Addicional: Podeu ampliar la informació a l'enllaç d'
          <a href='/ca/avis-legal'>Avís legal</a>.
        </p>
      </>
      `
    return privacyText
  }

  const privacyText = getPrivacyText()
  const privacyPolicyLink = getPrivacyPolicyLink()

  const captchaRef = useRef();
  const captchaToken = useRef();

  const { loaded, loading, error } = useContact();
  const prevloading = usePrevious(loading);

  useEffect(() => {
    if (prevloading && loaded) {
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.messageSent)}
        />,
      );
    }
  }, [intl, loaded, prevloading]);

  const onSubmit = (data) => {
    captchaRef.current
      .verify()
      .then(() => {
        dispatch(contact(getBaseUrl(pathname), {...data, recaptcha: captchaToken.current}));
      })
      .catch(() => {
        toast.error(
          <Toast
            error
            title={intl.formatMessage(messages.errorRecaptcha)}
            content={error.errorRecaptcha}
          />,
        );
      });
  };

  return (
    <>
      <BodyClass className='contactForm-template'/>
      <div id="contact-form">
          {error && (
            <Message
              icon="warning"
              negative
              attached
              header='vaya'
              content='hola'
            />
          )}
          <Form
            onSubmit={onSubmit}
            formData={{ blocksLayoutFieldname: {} }}
            submitLabel={intl.formatMessage(messages.send)}
            resetAfterSubmit
            title={intl.formatMessage(messages.contactForm)}
            loading={loading}
            schema={{
              fieldsets: [
                {
                  fields: [
                    'fullName', 'email', 'entity', 'jobPosition',
                    'solutions', 'message', 'privacyText', 'acceptCommercial', 'privacyPolicy',
                    'privacyPolicyLink', 'recaptcha'
                  ],
                  id: 'default',
                  title: intl.formatMessage(messages.default),
                },
              ],
              properties: {
                fullName: {
                  title: intl.formatMessage(messages.fullName),
                  placeholder: intl.formatMessage(messages.fullNamePlaceholder),
                  type: 'string',
                },
                email: {
                  title: intl.formatMessage(messages.email),
                  placeholder: intl.formatMessage(messages.emailPlaceholder),                
                  type: 'email',
                },
                entity: {
                  title: intl.formatMessage(messages.entity),
                  placeholder: intl.formatMessage(messages.entityPlaceholder),
                  type: 'string',
                },
                jobPosition: {
                  title: intl.formatMessage(messages.jobPosition),
                  placeholder: intl.formatMessage(messages.jobPositionPlaceholder),
                  type: 'string',
                },
                solutions: {
                  title: intl.formatMessage(messages.solutions),
                  widget: 'checkbox_list_widget',
                  valueList: [
                    { value: 'administracio_electronica', label: intl.formatMessage(messages.administracioElectronica)},
                    { value: 'audio_video_acta', label: intl.formatMessage(messages.audioVideoActa)},
                    { value: 'comunicacio_digital', label: intl.formatMessage(messages.comunicacioDigital)},
                    { value: 'govern_obert', label: intl.formatMessage(messages.governObert)},
                  ],
                },              
                message: {
                  title: intl.formatMessage(messages.message),
                  placeholder: intl.formatMessage(messages.messagePlaceholder),                
                  type: 'string',
                  widget: 'textarea',
                },
                privacyText: {
                  widget: 'html_label_widget',
                  html: privacyText,
                },              
                acceptCommercial: {
                  title: intl.formatMessage(messages.acceptCommercial),
                  type: 'boolean',
                },
                privacyPolicy: {
                  title: intl.formatMessage(messages.privacyPolicy),
                  widget: 'checkbox_widget',
                  required: true,
                },
                privacyPolicyLink: {
                  widget: 'html_label_widget',
                  html: privacyPolicyLink,
                },
                recaptcha: {
                  title: intl.formatMessage(messages.recaptcha),
                  widget: 'google_recaptcha_widget',
                  captchaRef: captchaRef,
                  captchaToken: captchaToken,
                },
              },
              required: ['fullName', 'email', 'message', 'privacyPolicy'],
            }}>
          </Form>
      </div>
    </>
  );
};

export default ContactForm;
