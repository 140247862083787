import React from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { keys, isEmpty, map, filter } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown  } from '@fortawesome/free-solid-svg-icons';


const NavItem = ({ item, lang, mobile }) => {
  const { settings } = config;

  const subItems = item.items

  if (isInternalURL(item.url) || item.url === '') {
    return (
      <>
      {(!isEmpty(item.items) && !mobile ) ? (
        <Dropdown 
          text={item.title} 
          inline 
          basic
          item
          >          
          <Dropdown.Menu className="mnPrincipal__second">
            {map(subItems, (item, index) => (
              <Dropdown.Item key={index}>               
                <NavLink
                  to={item.url === '' ? '/' : item.url}
                  key={item.url}
                  className="item"
                  exact={
                    settings.isMultilingual
                      ? item.url === `/${toBackendLang(lang)}`
                      : item.url === ''
                  }
                > 
                  {item.content_icon && (
                    <Icon name={item.content_icon} />
                  )}
                  {item.title} 
                </NavLink>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <NavLink
          to={item.url === '' ? '/' : item.url}
          key={item.url}
          className="item"
          activeClassName="current"
          exact={
            settings.isMultilingual
              ? item.url === `/${toBackendLang(lang)}`
              : item.url === ''
          }
        >
          {item.title}
        </NavLink>
      )}
      </>
    );
  } else {
    return (
      <a
        href={item.url}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
        target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
      >
        {item.title}
        {settings.openExternalLinkInNewTab && ( <span class="visually-hidden"><FormattedMessage id="Opens in new window" defaultMessage="Opens in new window"  /></span> )}
      </a>
    );
  }
};

export default NavItem;
