/**
 * RelatedNews component.
 * @module components/News/RelatedNews
 */

import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card, Segment, Grid, Label, Header, Container } from 'semantic-ui-react';
import { defineMessages, useIntl, FormattedMessage,  } from 'react-intl';
import { searchContent } from '@plone/volto/actions';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { keys, isEmpty, map, filter } from 'lodash';
import { getVocabulary } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';


const RelatedNews = props => {
  const content = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const relatedNews = useSelector((state) => {
    return filter(state.search.subrequests.related_news?.items, (item) => item.UID !== content.UID)
  });
  const vocabularySubjects = 'product.ecityclic_web.vocabularies.NewsitemSubjects';
  const itemsSubjects = useSelector(
    (state) =>
      state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
        ? state.vocabularies[vocabularySubjects].items
        : [],
    shallowEqual,
  );

  const getNewsItemSubject = function(Subject) {
    if (!itemsSubjects) {
      return Subject
    }
    for (let item of itemsSubjects) {
      if (item.value === Subject) return item.label
    }
    return Subject
  }

  useEffect(() => {
    dispatch(
      searchContent(
        '/' + content.language.token,
        {
          portal_type: ['News Item'],
          review_state: 'published',
          sort_on: 'effective',
          sort_order: 'reverse',
          newsitem_subjects: map(content.newsitem_subjects, (item) => item.token),
          b_size: 4,
          metadata_fields: ['effective', 'newsitem_subjects', 'hasPreviewImage', 'UID']
        },
        'related_news',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects, lang: content.language.token}));
  }, [dispatch]);

  return !isEmpty(relatedNews) ? (
    <>
      <BodyClass className='hasRelatedContent'/>
      <Segment as="section" basic id="relatedNews" className="relatedNews">
        <Container>
          <Header as="h2" className="relatedNews__title" ><FormattedMessage id="Related News" defaultMessage="Related News"/></Header>

          <Grid>
            <Grid.Row columns={12}>
            {relatedNews.slice(0, 3).map((item) => (
              <Grid.Column mobile={12} tablet={6} computer={4}  widescreen={4} key={item['@id']}>
                <Card fluid key={item['@id']}  as="figure" className={'newsItem position-relative ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')} >
                  <div className="image newsItem__img">
                    <Image 
                      className="newsItem__img"
                      item={item}
                      loading='lazy'
                      alt=""
                    />
                  </div>
                  <Card.Content as="figcaption" >
                    <Card.Meta>
                      <FormattedDate date={item.effective} className="newsItem__date "
                        format={{
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        }}
                      />
                      {item?.newsitem_subjects ? (
                        <Label.Group className="subjects newsItem__type">
                          {map(item.newsitem_subjects.slice(0, 1), (item, index) => (
                            <Label className="newsItem__subject" key={index}>{getNewsItemSubject(item)}</Label>
                          ))}
                        </Label.Group>
                      ) : null}
                    </Card.Meta> 
                    <Card.Header className="newsItem__title">
                      <UniversalLink
                        className="newsItem__link newsItem__title stretched-link" 
                        href={flattenToAppURL(item['@id'])}>
                        {item.title}
                      </UniversalLink>
                    </Card.Header>
                    <span className="readMoreItem" aria-hidden="true">
                      <FontAwesomeIcon className='readMoreItem__icon' icon={faCirclePlus} />
                      <FormattedMessage id="Read more" defaultMessage="Read more" />
                    </span>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
            </Grid.Row>
          </Grid>

        </Container>
      </Segment>
    </>
  ) : (
    <></>
  );
};

export default RelatedNews;