import _ from 'lodash';
import React from 'react';
import { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { Search } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import { withBlockExtensions } from '@plone/volto/helpers';
import { FormFieldWrapper } from '@plone/volto/components';
import { SidebarPortal } from '@plone/volto/components';
import { IconData } from './Data';
import IconView from './View';
import { Button, ButtonGroup, Segment } from 'semantic-ui-react';

const messages = defineMessages({
  Icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  friendly_name: {
    id: 'iconName',
    defaultMessage: 'Icon name',
  },
  placeholder: {
    id: 'Search for an icon',
    defaultMessage: 'Search for an icon',
  },
});

const IconEdit = (props) => {
  const intl = useIntl();
  const { data, onChangeBlock, block, selected } = props;
  return (
    <>
      <Segment>
        <IconView {...props} />
      </Segment>
      <SidebarPortal selected={selected}>
        <IconData {...props} />
      </SidebarPortal>
    </>
  );
};

export default IconEdit;
