import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  background: {
    id: 'background',
    defaultMessage: 'Background',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
});


const itemSchema = (props) => ({
  title: 'Item',
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description'],
    },
  ],

  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'text',
    },
  },

  required: ['title'],
});


export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'background', 'items'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      description: {
        title: props.intl.formatMessage(messages.description),
        widget: 'text',
      },
      background: {
        title: props.intl.formatMessage(messages.background),
        choices: [
          ['bg-background-01', 'Background 1'],
          ['bg-background-02', 'Background 2'],
          ['bg-background-03', 'Background 3'],
        ],
      },
      items: {
        title: 'Items',
        description: 'Add Items',
        schema: itemSchema(props),
        widget: 'object_list',
      },
    },
  };
};

export default schema;