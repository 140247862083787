import { Container, Segment, Header, Card, Grid, List, Image } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';
import { Icon, NumberAnimation } from '../../';


const View = (props) => {
  const { data, mode } = props;
  const variantClass = ' ' + (data?.variant ? data.variant : 'variant-iconlisting-01')
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')
  const largeScreen = data?.variant === 'variant-iconlisting-02' ? 4 : 3
  const widescreen = data?.variant === 'variant-iconlisting-02' ? 4 : 3
  const iconsColumns = {
    mobile: 12,
    tablet: 6,
    computer: 5,
    largeScreen: largeScreen,
    widescreen: widescreen,
  }

  return (
    <section className={' iconList ' + fullWidthClass + backgroundClass + variantClass}>
      <Container className="">
          {data?.title && (<Header as="h2" className={"iconList__title " + (data?.description ? 'iconList__title--wDesc ' : 'iconList__title--noDesc ')}>{data.title}</Header>)}
          {data?.description && (<p className='iconList__desc'>{data.description}</p>)}

          {!isEmpty(data?.items) && (
            <Grid className="">
              <Grid.Row columns={12} className="justify-content-center">
                {map(data.items , (item) => (
                  <Grid.Column key={item['@id']} {...iconsColumns} className="mb-4 mb-lg-0">
                  <Card className='iconItem position-relative' fluid as="article">
                    <Card.Content className="iconItem__heading">
                      {item?.icon && (
                        <Icon name={item.icon} className="iconItem__icon" />
                      )}
                      {item?.title && (
                        <Card.Header className="iconItem__header" as={ variantClass == ' variant-iconlisting-02' ? ('span') : ('h3')} >
                          {(item?.link && mode !== 'edit') ? (
                            <UniversalLink href={item.link} className="iconItem__link stretched-link">{item.title}</UniversalLink>
                          ) : (
                            <>                            
                              {( variantClass == ' variant-iconlisting-02' && !isNaN(parseInt(item.title.replace("+ ", ""))) ) ? (
                                <>
                                  + <NumberAnimation n={parseInt(item.title.replace("+ ", ""))}></NumberAnimation>
                                </>
                              ) : (
                                <> 
                                  {item.title}                               
                                </>
                              )}
                              
                            </>
                          )}
                        </Card.Header>
                      )}
                    </Card.Content>
                    {(item?.description || item?.link) && (
                      <Card.Content extra>
                        {item?.description && (<p className='iconItem__description'>{item.description}</p>)}
                        {item?.link && (
                          <span className="readMoreItem" aria-hidden="true">
                            <FontAwesomeIcon className='readMoreItem__icon' icon={faCirclePlus} />
                            {item.textLink ? (<span>{item.textLink}</span>) : (<FormattedMessage id="Read more" defaultMessage="Read more" />)}
                          </span>
                        )}
                      </Card.Content>
                    )}
                  </Card>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          )}

      </Container>
    </section>
  )
};

export default View;