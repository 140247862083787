import CustomIcon from "./CustomIcons"
import { customIcons } from "./CustomIcons"
import { brandsIcons } from './'


const Icon = (props) => {
  const { name, size, className } = props;
  const prefix = brandsIcons.includes(name) ? 'fa-brands' : 'fa-solid';
  const formatedName = name
    .split(' ')
    .map((separetedName) => `fa-${separetedName}`)
    .join(' ');

  return (
    <>
      {customIcons.includes(name) ? (
        <CustomIcon {...props} className={`${className ? className : ''} ${size ? size : ''}`} />
      ) : (
        <span className={`${prefix} ${formatedName} ${className ? className : ''} ${size ? size : ''}`} aria-hidden="true"></span>
      )}
    </>
  );
};

export default Icon;
