/**
 * NewsListing component.
 * @module components/News/NewsListing
 */

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, List, Label, Grid, Card, Form } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, FormattedDate, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { toBackendLang } from '@plone/volto/helpers/Utils/Utils';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

const messages = defineMessages({
  labelText: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  placeholderText: {
    id: 'What are you looking for?',
    defaultMessage: 'What are you looking for?',
  },
  defaultOptionSubjects: {
    id: 'All subjects',
    defaultMessage: 'All subjects',
  },
  labelSubject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  placeholderAllSubjects: {
    id: 'All subjects',
    defaultMessage: 'All subjects',
  },
  date: {
    id: 'Date',
    defaultMessage: 'Date',
  },
});


const NewsListing = (props) => {
  const { content } = props;
  const lang = content.language.token;
  const dispatch = useDispatch();
  const intl = useIntl();
  const news = useSelector((state) => state.search.subrequests.news?.items);
  const total = useSelector((state) => state.search.subrequests.news?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [search, setSearch] = useState(0);
  const [searchableTextInput, setSearchableText] = useState('');
  const [searchableText] = useDebounce(searchableTextInput, 500);
  const [subject, setSubject] = useState('');
  const [date, setDate] = useState(null);
  const [focused, setFocused] = useState(null);

  const vocabularySubjects = 'product.ecityclic_web.vocabularies.NewsitemSubjects';

  const itemsSubjects = useSelector(
    (state) =>
      state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
        ? [{value: '', label: intl.formatMessage(messages.defaultOptionSubjects)}, ...state.vocabularies[vocabularySubjects].items]
        : [],
    shallowEqual,
  );

  const getNewsItemSubject = function(Subject) {
    if (!itemsSubjects) {
      return Subject
    }
    for (let item of itemsSubjects) {
      if (item.value === Subject) return item.label
    }
    return Subject
  }

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects, lang: content.language.token}));
  }, [dispatch]);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['News Item'],
      b_size: pageSize,
      b_start: currentPage * pageSize,
      review_state: 'published',
      SearchableText: searchableText,
      newsitem_subjects: subject,
      sort_on: 'effective',
      sort_order: 'reverse',
      metadata_fields: ['effective', 'newsitem_subjects',  'hasPreviewImage']
    };

    if (date) {
      contentFilter['effective.query'] = [date.format('YYYY/MM/DD') + ' 00:00', date.format('YYYY/MM/DD') + ' 23:59']
      contentFilter['effective.range'] = 'min:max'
    }

    dispatch(
      searchContent(
        flattenToAppURL(content['@id']),
        contentFilter,
        'news',
      ),
    );
  }, [dispatch, currentPage, pageSize, searchableText, date, subject, search]);

  useEffect(() => {
    const searchForm = document.querySelector('.searchList');
    const searchFormPos = searchForm ? searchForm.offsetTop : 0;
    let scrollPos = window.scrollY;
    if (scrollPos > searchFormPos && searchForm) {
      window.scrollTo({top: searchFormPos})
    }

  }, [currentPage]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSearchableText = (event, data) => {
    event.preventDefault();
    setSearchableText(data.value);
    setCurrentPage(0);
  };

  const onChangeSubject = (event, data) => {
    event.preventDefault();
    setSubject(data.value);
    setCurrentPage(0);
  };

  const onDateChange = (data) => {
    setDate(data)
    setCurrentPage(0);
  };

  const onFocusChange = (data) => {
    setFocused(data.focused)
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  return (
    <>
      <BodyClass className='newslisting'/>

      {hasBlocksData(content) ? (
        <Container>
          <Grid>
            <Grid.Column width={12}>
              <RenderBlocks {...props} />
            </Grid.Column>
          </Grid>
        </Container>
      ): null}

      <Form  className="searchList   " onSubmit={onSubmit}>          
        <Container  className="pb-4  d-print-none">
          <Form.Group widths="equal" className="align-items-end">
            <Form.Input 
              id="textInputId"
              fluid 
              label={intl.formatMessage(messages.labelText)} 
              focus
              name="searchableText"
              value={searchableTextInput}
              onChange={onChangeSearchableText}
              placeholder={intl.formatMessage(messages.placeholderText)} 
              type="text" 
              />
            <Form.Select 
              id="subjectSelectId"
              fluid 
              label={intl.formatMessage(messages.labelSubject)}
              options={map(itemsSubjects, (item, index) => {return {
                key: index,
                value: item.value,
                text: item.label,
              }})}
              name="subject"
              value={subject}
              onChange={onChangeSubject}
              placeholder={intl.formatMessage(messages.placeholderAllSubjects)}
              />
            <div className="field">
              <label htmlFor="date"><FormattedMessage id="Date" defaultMessage="Date"  /></label>
              <div className="date-widget-wrapper">
                <div className="ui input date-input">
                  <SingleDatePicker
                    date={date}
                    onDateChange={onDateChange}
                    focused={focused}
                    numberOfMonths={1}
                    onFocusChange={onFocusChange}
                    noBorder
                    showClearDate
                    displayFormat={moment
                      .localeData(toBackendLang(lang))
                      .longDateFormat('L')}
                    isOutsideRange={() => false}
                    placeholder={intl.formatMessage(messages.date)}
                    id="date"
                  />
                </div>
              </div>
            </div>
            <Form.Button inverted className="searchList__send searchList__btn " ><FormattedMessage id="Search" defaultMessage="Search"  /></Form.Button>
          </Form.Group>
        </Container>
      </Form>


      <>
        {!isEmpty(news) ? (
          <Container className="news newsListing"> 
            <Grid>
              <Grid.Row columns={12}>
                {news.map((item) => (
                  <Grid.Column className="mb-4" mobile={12} tablet={6} computer={4}  widescreen={3} key={item['@id']}>
                    <Card fluid as="article" className={'newsItem position-relative ' + (item?.hasPreviewImage ? ' newsItem-hasImage' : ' newsItem-noImage')}> 
                      <div className="image newsItem__img">
                        <Image 
                            item={item}
                            loading='lazy'
                            alt=""
                            sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1599px) 33.33vw, 25vw"
                          />
                      </div>
                      <Card.Content  >
                        <Card.Meta>
                          <FormattedDate date={item.effective} className="newsItem__date "
                            format={{
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }}
                          />
                          {item?.newsitem_subjects  ? (
                            <Label.Group className="subjects newsItem__subject">
                              {map(item.newsitem_subjects.slice(0, 1), (item, index) => (
                                <Label className="newsItem__subject" key={index}>{getNewsItemSubject(item)}</Label>
                              ))}
                            </Label.Group>
                          ) : null}
                        </Card.Meta>
                        <Card.Header className="newsItem__title" as="h2">
                          <UniversalLink
                            className="newsItem__link newsItem__title stretched-link" 
                            href={flattenToAppURL(item['@id'])}>
                            {item.title}
                          </UniversalLink>
                        </Card.Header>
                        <span className="readMoreItem" aria-hidden="true">
                          <FontAwesomeIcon className='readMoreItem__icon' icon={faCirclePlus} />
                          <FormattedMessage id="Read more" defaultMessage="Read more" />
                        </span>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>

            {total > pageSize && (
              <nav className="pagination">
                <Pagination 
                  current={currentPage}
                  total={Math.ceil(total / pageSize)}
                  onChangePage={onChangePage}
                  onChangePageSize={onChangePageSize}
                />
              </nav>
            )}

          </Container>
        ) : (
          <Container className="news newsListing"> 
            <p><FormattedMessage id="No news found" defaultMessage="No news found"  /></p>
          </Container>
          )} 
      </>


    </>
  );
};


export default NewsListing;
