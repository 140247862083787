import React from 'react';
import NavItem from './NavItem';

const NavItems = ({ items, lang, mobile }) => {

  return (
    <>
      {items.map((item) => (
        <div className='navItem' key={item.url}  >
          <NavItem item={item} lang={lang} key={item.url} mobile={mobile} />
        </div>
      ))}
    </>
  );
};

export default NavItems;
