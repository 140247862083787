/**
 * NewsListing component.
 * @module components/News/NewsListing
 */

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, Label, Grid, Card, Form, Header } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { UniversalLink, FormattedDate, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { toBackendLang } from '@plone/volto/helpers/Utils/Utils';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Icon } from '../';


const messages = defineMessages({
  defaultOptionSolutions: {
    id: 'Select a solution',
    defaultMessage: 'Select a solution',
  },
  labelSolution: {
    id: 'Solution',
    defaultMessage: 'Solution',
  },
  placeholderAllSolutions: {
    id: 'All solutions',
    defaultMessage: 'All solutions',
  },
});


const Solution = props => {
  const {item, solutions, vocabulary} = props
  const getSolution = function(solution) {
    for (let item of solutions) {
      if (item.UID === solution) return item
    }
    return false
  }
  const getSolutionVocabulary = function(solution) {
    for (let item of vocabulary) {
      if (item.value === solution) return item
    }
    return false
  }
  const solution = getSolution(item)
  const solutionVocabulary = getSolutionVocabulary(item)

  return solution ? (
    <>
      {solution?.content_icon && (
        <div className="greenIcon">
          <Icon name={solution.content_icon} />
        </div>
      )}
      <span className='pt-1'>{solution.title}</span>
    </>
  ) : (
    <span className='pt-1'>{solutionVocabulary.label}</span>
  );
};


const ProjectsListing = (props) => {
  const { content } = props;
  const lang = content.language.token;
  const dispatch = useDispatch();
  const intl = useIntl();
  const projects = useSelector((state) => state.search.subrequests.projects?.items);
  const solutions = useSelector((state) => state.search.subrequests.solutions?.items);
  const total = useSelector((state) => state.search.subrequests.projects?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState(0);
  const [solution, setSolution] = useState('');

  const vocabularySolutions = 'product.ecityclic_web.vocabularies.Solutions';

  const itemsSolutions = useSelector(
    (state) =>
      state.vocabularies[vocabularySolutions] && state.vocabularies[vocabularySolutions].items
        ? [{value: '', label: intl.formatMessage(messages.defaultOptionSolutions)}, ...state.vocabularies[vocabularySolutions].items]
        : [],
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularySolutions, lang: content.language.token}));
  }, [dispatch]);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['Solution'],
      review_state: 'published',
      metadata_fields: ['content_icon', 'UID']
    };

    dispatch(
      searchContent(
        '/' + lang,
        contentFilter,
        'solutions',
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['Project'],
      b_size: pageSize,
      b_start: currentPage * pageSize,
      review_state: 'published',
      related_solutions: solution,
      sort_on: 'effective',
      sort_order: 'reverse',
      metadata_fields: ['effective', 'related_solutions',  'hasPreviewImage', 'text']
    };

    dispatch(
      searchContent(
        flattenToAppURL(content['@id']),
        contentFilter,
        'projects',
      ),
    );
  }, [dispatch, currentPage, pageSize, solution, search]);

  useEffect(() => {
    const searchForm = document.querySelector('.searchList');
    const searchFormPos = searchForm ? searchForm.offsetTop : 0;
    let scrollPos = window.scrollY;
    if (scrollPos > searchFormPos && searchForm) {
      window.scrollTo({top: searchFormPos})
    }

  }, [currentPage]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSolution = (event, data) => {
    event.preventDefault();
    setSolution(data.value);
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  return (
    <>
      <BodyClass className='projectslisting'/>

      {hasBlocksData(content) ? (
        <Container>
          <RenderBlocks {...props} />
        </Container>
      ): null}

      <Form  className="searchList searchList--projects" onSubmit={onSubmit}>
        <Grid container>
          <Grid.Row columns={12}>
            <Grid.Column mobile={12} tablet={10} computer={6} className="d-flex">
              <Form.Group className="align-items-end flex-grow-1">
                <Form.Select 
                  id="solutionSelectId"
                  fluid 
                  label={intl.formatMessage(messages.labelSolution)}
                  options={map(itemsSolutions, (item, index) => {return {
                    key: index,
                    value: item.value,
                    text: item.label,
                  }})}
                  name="solution"
                  value={solution}
                  onChange={onChangeSolution}
                  placeholder={intl.formatMessage(messages.placeholderAllSolutions)}
                  className="flex-grow-1"
                  />
                <Form.Button inverted className="searchList__send searchList__btn  mt-3 mt-sm-0"><FormattedMessage id="Search" defaultMessage="Search"  /></Form.Button>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>


      {!isEmpty(projects) ? (
        <div className="projectsList" aria-live='polite'>
          {projects.map((item) => (
            <Segment basic className="projectItem position-relative" as="article"  key={item['@id']}>
              <Grid container columns={12}  >
                <Grid.Column mobile={12} tablet={12} computer={6} >
                  <Header className="projectItem__title" as="h2">
                    {item.title}
                  </Header>
                  {item?.description && (<p className="projectItem__desc">{item.description} </p>)}

                  {item?.text && (
                    <div
                      className="projectItem__text"
                      dangerouslySetInnerHTML={{
                        __html: flattenHTMLToAppURL(item.text),
                      }}
                    />
                  )}

                  <section className='projectItem__subject'>
                    <Header as="h3" className="projectItem__subject__title"><FormattedMessage id="Related solutions" defaultMessage="Related solutions"  /></Header>
                    {item?.related_solutions  ? (
                      <ul className="projectItem__subject__list list-unstyled">                      
                        {map(item.related_solutions , (item, index) => (
                          <li  key={index} className="subjects projectItem__subject__item">
                            <Solution item={item} solutions={solutions} vocabulary={itemsSolutions}></Solution>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </section>
                </Grid.Column>
                <Grid.Column mobile={12} tablet={12} computer={5}>
                  <Image 
                    className="projectItem__img"
                    item={item}
                    loading='lazy'
                    alt=""
                    sizes="(max-width: 62em) 100vw, 32rem"
                  />
                </Grid.Column>
              </Grid>
            </Segment>
          ))}

          {total > pageSize && (
            <Container>
              <nav className="pagination">
                <Pagination 
                  current={currentPage}
                  total={Math.ceil(total / pageSize)}
                  onChangePage={onChangePage}
                  onChangePageSize={onChangePageSize}
                />
              </nav>
            </Container>
          )}
        </div>
      ) : (
        <Container className="projects"> 
          <p><FormattedMessage id="No projects found" defaultMessage="No projects found"  /></p>
        </Container>
      )}

    </>
  );
};


export default ProjectsListing;