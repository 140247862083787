/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types'; 
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';
import {
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
  chooseLanguage: {
    id: 'Choose language',
    defaultMessage: 'Choose your language'
  }
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  return settings.isMultilingual ? (
	<div className="langSelector"
    text={currentLang} 
    aria-label={intl.formatMessage(messages.chooseLanguage)}
    role="listbox"
    >  
			{map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        return (
          <Link 
            aria-label={`${intl.formatMessage(
              messages.switchLanguageTo,
            )} ${langmap[lang].nativeName.toLowerCase()}`}
            className={cx({ selected: toReactIntlLang(lang) === currentLang })}
            to={translation ? flattenToAppURL(translation['@id']) : `/${lang}`} 
            onClick={() => {
              props.onClickAction();
            }}
            key={`language-selector-${lang}`}
            hrefLang={lang}
            aria-selected={toReactIntlLang(lang) === currentLang}
            role="option"
          >
            {lang}
          </Link>
        );
			})} 
	</div>
  ) : (
    <Helmet>
      <html lang={toReactIntlLang(settings.defaultLanguage)} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
