/**
 * Search component.
 * @module components/Search/Search
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, List, Label, Grid, Card, Form, Header, Message } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import classNames from 'classnames';
import qs from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const messages = defineMessages({
  Search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  labelText: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  placeholderText: {
    id: 'What are you looking for?',
    defaultMessage: 'What are you looking for?',
  },
});



const SearchView = (props) => {

  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const results = useSelector((state) => state.search.subrequests.results?.items);
  const total = useSelector((state) => state.search.subrequests.results?.total);
  const navroot = useSelector((state) => state.navroot?.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(0);
  const [sortOn, setSortOn] = useState('relevance');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [searchableTextInput, setSearchableText] = useState('');
  const [searchableText] = useDebounce(searchableTextInput, 1000);
  const [portalTypes, setPortalTypes] = useState([]);

  const documentFirstHeading = props.searchableText ? (
      <FormattedMessage
        id="Search results for {term}"
        defaultMessage="Search results for {term}"
        values={{
          term: <q>{props.searchableText}</q>,
        }}
      />
    ) : (
      <FormattedMessage
        id="Search results"
        defaultMessage="Search results"
      />
  );

  const vocabularyPortalTypes = 'plone.app.vocabularies.UserFriendlyTypes';
  const itemPortalTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyPortalTypes] && state.vocabularies[vocabularyPortalTypes].items
        ? state.vocabularies[vocabularyPortalTypes].items
        : [],
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyPortalTypes, lang: content.language.token}));
  }, [dispatch]);

  useEffect(() => {
    setSearchableText(qs.parse(props.history.location.search).SearchableText)
  }, [location.search]); 

  useEffect(() => {
    if (qs.parse(props.history.location.search).SearchableText !== searchableText) {
      setSearchableText(qs.parse(props.history.location.search).SearchableText)
      return
    }

    let contentFilter = {
      b_size: pageSize,
      b_start: currentPage * pageSize,
      SearchableText: searchableText,
      sort_on: sortOn,
      sort_order: sortOrder,
    }

    if (!isEmpty(portalTypes)) {
      contentFilter.portal_type = portalTypes
    }

    dispatch(
      searchContent(
        flattenToAppURL(navroot.navroot['@id']),
        contentFilter,
        'results',
      ),
    );
  }, [dispatch, currentPage, pageSize, searchableText, portalTypes, sortOn, sortOrder, search]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSearchableText = (event, data) => {
    event.preventDefault();
    history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(data.value)}`);
    setSearchableText(data.value);
    setCurrentPage(0);
  };

  const onChangePortalTypes = (event, data) => {
    if (data.checked) {
      setPortalTypes(portalTypes => [...portalTypes, data.name])
    } else {
      setPortalTypes(portalTypes => [...portalTypes.filter((item) => item != data.name)])
    }
    setCurrentPage(0);
  };

  const onChangeSort = (event, data) => {
    setSortOn(data.name)
    if (data.name === 'effective') {
      setSortOrder('reverse')
    } else {
      setSortOrder('ascending')
    }
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  return (
    <>
      <BodyClass className='searchlisting headerNegativeBottom'/>
      <Helmet title={intl.formatMessage(messages.Search)} />

        <Grid container>
          <Grid.Row>
            <Grid.Column width={12}>
              <Form  className="searchList searchList-search px-5" onSubmit={onSubmit}>          
                <Form.Group widths="equal">
                  <Form.Input 
                    id="textInputId"
                    fluid 
                    label={intl.formatMessage(messages.labelText)} 
                    focus
                    name="searchableText"
                    value={searchableTextInput}
                    onChange={onChangeSearchableText}
                    placeholder={intl.formatMessage(messages.placeholderText)} 
                    type="text" 
                    />
                </Form.Group>
                <Form.Button className="searchList__send searchList__btn" primary><FormattedMessage id="Search" defaultMessage="Search"  /></Form.Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid container>
          <Grid.Row>
            <Grid.Column width={12}>
              <Header as="h2" size="large" className="text-primary border-bottom pb-4 justify-content-center">
                <FormattedMessage id="Search results" defaultMessage="Search results"  />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={12} tablet={4} computer={4} largeScreen={3} widescreen={3}>
              <Form className="searchListFacets" onSubmit={onSubmit} >          
                {!isEmpty(itemPortalTypes) && (
                  <>
                  <Header as="h3" size="medium" >
                    <FormattedMessage id="Types" defaultMessage="Types"/>
                  </Header>
                    {itemPortalTypes.map((item, index) => (
                      <Form.Checkbox
                          key={index}
                          id={`portalType-${index}`}
                          label={item.label} 
                          name={item.value}
                          checked={portalTypes.includes(item.value)}
                          onChange={onChangePortalTypes}
                          />
                      
                    ))}
                  </>
                )}
              </Form>
            </Grid.Column>

            <Grid.Column mobile={12} tablet={8} computer={8} largeScreen={9} widescreen={9}>

              {!isEmpty(results) ? (
                <>
                  <div className="items_total sectionSort">
                    <span className="sectionSort__total">
                      {total}{' '}
                      <FormattedMessage
                        id="results found"
                        defaultMessage="results"
                      />
                    </span>
                    
                    <div className="d-flex flex-wrap align-items-center">
                        <span className="sort-by text-primary text-uppercase fw-bold">
                          <FormattedMessage
                            id="Sort By:"
                            defaultMessage="Sort by:"
                          />
                        </span>
                        <Button.Group>
                      
                          <Button
                            onClick={onChangeSort}
                            name="relevance"                            
                            size="tiny"
                            className={classNames('button-sort', {
                              'button-active': sortOn === 'relevance',
                            })}
                          >
                            <FormattedMessage
                              id="Relevance"
                              defaultMessage="Relevance"
                            />
                          </Button>
                          <Button
                          onClick={onChangeSort}
                            name="sortable_title"
                            size="tiny"
                            className={classNames('button-sort', {
                              'button-active': sortOn === 'sortable_title',
                            })}
                          >
                            <FormattedMessage
                              id="Alphabetically"
                              defaultMessage="Alphabetically"
                            />
                          </Button>
                          <Button
                            onClick={onChangeSort}
                            name="effective"
                            size="tiny"
                            className={classNames('button-sort', {
                              'button-active': sortOn === 'effective',
                            })}
                          >
                            <FormattedMessage
                              id="Date (newest first)"
                              defaultMessage="Date (newest first)"
                            />
                          </Button>
                        </Button.Group>
                    </div>
                  </div>

                  <Segment basic className="results px-0">
                  {results.map((item) => (
                    <Card as="article" fluid className="searchResultItem mb-5" key={item['@id']} >
                      <Card.Header as="h3" className="searchResultItem__title">
                        <UniversalLink
                          className="searchResultItem__link stretched-link" 
                          href={flattenToAppURL(item['@id'])}>
                          {item.title}
                        </UniversalLink>
                      </Card.Header>
                      <Card.Content>
                        <p className="searchResultItem__path">Home / Lorem ipsum / Dolor sit  / Unde fugit fugiat dolorem est omnis autem.</p>
                        {item?.description && (                        
                          <p className="searchResultsItem__desc">{item.description}</p>
                          )}
                        <span className="squareMore" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight} /></span>
                      </Card.Content>
                    </Card>
                     
                  ))}
                  </Segment>

                  {total > pageSize && (
                    <nav className="pagination">
                      <Pagination 
                        current={currentPage}
                        total={Math.ceil(total / pageSize)}
                        onChangePage={onChangePage}
                        onChangePageSize={onChangePageSize}
                      />
                    </nav>
                  )}
                </>
              ) : (
                <Message className="results"> 
                  <FormattedMessage id="No results found" defaultMessage="No results found"  />
                </Message>
              )} 
            </Grid.Column>
          </Grid.Row>
        </Grid>
    </>
  );
};


const Search = (props) => {
  const { content } = props;
  const navroot = useSelector((state) => state.navroot?.data);
  const token = useSelector((state) => state?.userSession?.token);
  const history = useHistory();
  useEffect(() => {
    if (!token) {
      history.replace(flattenToAppURL(navroot.navroot['@id']));
    }
  }, [content, history]);
  
  return (<SearchView {...props}></SearchView>)
}

export default Search;
