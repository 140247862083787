import React from 'react';
import { Container, Grid, Header, Card } from 'semantic-ui-react';
import { UniversalLink, Image } from '@plone/volto/components';
import { keys, isEmpty, map } from 'lodash'; 
import { Icon } from '../../';


const View = (props) => {
  const { data, mode } = props;
  const backgroundClass = ' ' + (data?.background ? data.background : '');
  const fullWidthClass = ' ' + (mode !== 'edit' ? 'full-width' : 'cardsListing--editMode');
  return (
    <section className={'cardsListing ' + fullWidthClass + backgroundClass}>
      <Container className={(data?.background && mode === 'edit') ? data.background : ''}>
        <Grid >
          { (data?.title || data?.description) && (
            <Grid.Row columns={12}  className="justify-content-center">
              <Grid.Column mobile={12} tablet={10} computer={8} largeScreen={6} widescreen={6}>
                {data?.title && (<Header as="h2" className={"cardsListing__title" + (data?.description ? ' cardsLisgin__title--wDesc' : ' cardsLisgin__title--noDesc')}>{data.title}</Header>)}
                {data?.description && (<p className="cardsListing__desc">{data.description}</p>)}
              </Grid.Column>
            </Grid.Row>
          )}
          {!isEmpty(data?.cards) && (
            <Grid.Row columns={12} className="cardsListing__cardGroup">
              {map(data.cards, (item) => (
                <Grid.Column mobile={12} tablet={6} computer={5} largeScreen={4} widescreen={4} key={item['@id']} >
                  <Card as="article" className='cardsListingItem position-relative' fluid>
                    <Card.Content>
                      {item.icon && (
                        <Icon
                          className="cardsListingItem__icon"
                          name={item.icon}
                        />
                      )}
                      {item.title && (
                        <Card.Header as="h3" className="cardsListingItem__title">
                          {item?.link ? (
                            <UniversalLink href="" className="cardsListingItem__link streched-link">
                              {item.title}
                            </UniversalLink>
                          ) : (
                            <>{item.title}</>
                          )}
                        </Card.Header>
                      )}
                      {item.description && (
                        <Card.Meta as="p" className="cardsListingItem__desc">
                          {item.description}
                        </Card.Meta>
                      )}
                    </Card.Content>
                  </Card>                   
                </Grid.Column>                
              ))}
            </Grid.Row>
          )}

        </Grid>

      </Container>
      
    </section>

  )
};
export default View;