import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  bottomImage: {
    id: 'Bottom Image',
    defaultMessage: 'Bottom Image',
  },
  contactLink: {
    id: 'Contact Link',
    defaultMessage: 'Contact Link',
  },
  background: {
    id: 'background',
    defaultMessage: 'Background',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
});


const itemSchema = (props) => ({
  title: 'Item',
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'image', 'bottomImage'],
    },
  ],

  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'text',
    },
    image: {
      title: props.intl.formatMessage(messages.image),
      widget: 'attachedimage',
    },
    bottomImage: {
      title: props.intl.formatMessage(messages.bottomImage),
      widget: 'attachedimage',
    },
  },

  required: ['title'],
});


export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'contactLink', 'background', 'items'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      description: {
        title: props.intl.formatMessage(messages.description),
        widget: 'text',
      },
      contactLink: {
        title: props.intl.formatMessage(messages.contactLink),
        widget: 'url',
      },
      background: {
        title: props.intl.formatMessage(messages.background),
        choices: [
          ['bg-background-01', 'Background 1'],
          ['bg-background-02', 'Background 2'],
          ['bg-background-03', 'Background 3'],
        ],
      },
      items: {
        title: 'Items',
        description: 'Add Items',
        schema: itemSchema(props),
        widget: 'object_list',
      },
    },
  };
};

export default schema;