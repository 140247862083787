/**
 * Offer component.
 * @module components/Offer/Offer
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Container, Grid, Segment, Card, Header, Button } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

import { OfferForm } from './';
 



const Offer = (props) => {
  const { content } = props;

  return (
    <>

      {hasBlocksData(content) ? (
        <Container className="offersBody">
          <RenderBlocks {...props} />
        </Container>
      ): null}
 
      <OfferForm />

    </>
  );
};


export default Offer;
