import React from 'react';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';


const HtmlLabelWidget = (props) => {
    return (
        <>
            <div id={props.id}
              className={props?.className ? props.className : ''}
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(props.html),
              }}
            />
        </>
    );
};

export default HtmlLabelWidget;