import { Container, Header, Card } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';


const View = (props) => {
  const { data, mode } = props;
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')

  return (
    <section className={'timeLineList ' + fullWidthClass + backgroundClass}>
      <Container >
          {data?.title && (<Header as="h2" className={"timeLineList__title " + (data?.description ? 'timeLineList__title--wDesc ' : 'timeLineList__title--noDesc ')}>{data.title}</Header>)}
          {data?.description && (<p className='timeLineList__desc'>{data.description}</p>)}
          {!isEmpty(data?.items) && (
            <div className="timeLineList__list">
              {map(data.items , (item) => (
                <Card className='timeLineItem ' fluid as="article" key={item['@id']}>
                  <Card.Content>
                    {item?.title && (<Card.Header  as="h3" className="timeLineItem__header">{item.title}</Card.Header>)}
                    {item?.description && (<Card.Description as="p" className='timeLineItem__description'>{item.description}</Card.Description>)}
                  </Card.Content>
                </Card>
              ))}
            </div>
          )}
      </Container>
    </section>
  )
};

export default View;