/**
 * applyOffer reducer.
 * @module reducers/applyOffer/applyOffer
 */

import { APPLY_OFFER } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
};

/**
 * applyOffer reducer.
 * @function applyOffer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function applyOffer(state = initialState, action = {}) {
  switch (action.type) {
    case `${APPLY_OFFER}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${APPLY_OFFER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${APPLY_OFFER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
