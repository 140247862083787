import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import { Icon } from '../../';
import {
  Card
} from 'semantic-ui-react';

const IconView = (props) => {
  const { data } = props;
  const formatedName =
    data.content_icon &&
    data.content_icon
      .split(' ')
      .map((separetedName) => `fa-${separetedName}`)
      .join(' ');

  return data.content_icon ? (
    <>
      {data.variation === 'default' ? (
        <Card className="iconLinkItem " href={data.url}>
          {data.content_icon && (
            <Icon
              className="iconLinkItem__icon"
              name={data.content_icon}
            />
          )}
          <Card.Content as="span">
            {data.title && (
              <span className="iconLinkItem__title">{data.title}</span>
            )}
            {data.description && (
              <span className="iconLinkItem__text">{data.description}</span>
            )}
          </Card.Content>
        </Card>        
      ) : (
        <Card className="iconCard " fluid href={data.url}>
          <Icon
            className="iconCard__icon"
            name={data.content_icon}
          />
          <Card.Content>
            {data.title && (
              <Card.Header className="iconCard__title" as="h3">{data.title}</Card.Header>
            )}
            {data.description && (
              <Card.Description className="iconCard__description" as="p">{data.description}</Card.Description>
            )}
          </Card.Content>
        </Card>
      )}
    </>
  ) : (
    <> </>
  );
};

export default IconView;
