/**
 * contact actions.
 * @module actions/contact/contact
 */

import { CONTACT } from '../../constants/ActionTypes';

/**
 * contact function
 * @function contact
 * @param {string} url Content url.
 * @param {Object} Form data
 * @returns {Object} contact action.
 */
export function contact(url, data) {
  return {
    type: CONTACT,
    request: {
      op: 'post',
      path: `${url}/@contact`,
      data: data,
    },
  };
}
