import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import { getNavroot } from '@plone/volto/actions';
import { hasApiExpander, getBaseUrl, flattenToAppURL } from '@plone/volto/helpers';


const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

const SearchWidget = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const navroot = useSelector((state) => state.navroot?.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';

  const history = useHistory();
  const onChangeText = (event, { value }) => {
    setText(value);
  };
  const pathname = props.pathname;
  const onSubmit = (event) => {
    const path =
      pathname?.length > 0 ? `&path=${encodeURIComponent(pathname)}` : '';

    history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(text)}${path}`);
    // reset input value
    setText('');
    event.preventDefault(); 
  };

  useEffect(() => {
    if (!hasApiExpander('navroot', getBaseUrl(pathname))) {
      dispatch(getNavroot(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  return (
    <Form action={`${navroot?.navroot?.['@id']}/search`} onSubmit={onSubmit}>
			<label className="searchbox__label" htmlFor="SearchableTextId" >{intl.formatMessage(messages.search)}</label>
      <Form.Field className="searchbox">
				<Input
					focus
					onChange={onChangeText}
					name="SearchableText"
					value={text}
					transparent
					autoComplete="off"
					placeholder={intl.formatMessage(messages.searchSite)} 
					id="SearchableTextId"
					className="searchbox__input"
					/>
        <button type="submit" className="searchbox__button" > 
					<FontAwesomeIcon icon={faMagnifyingGlass} className="searchbox__button__icon-glass" />
					<span className="searchbox__button__text visually-hidden">{intl.formatMessage(messages.search)}</span>
					<FontAwesomeIcon className="searchbox__button__icon-arrow d-none" icon={faArrowRightLong}  />
        </button>
      </Form.Field>
    </Form>
  );
};

export default SearchWidget;