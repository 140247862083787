/**
 * applyOffer actions.
 * @module actions/applyOffer/applyOffer
 */

import { APPLY_OFFER } from '../../constants/ActionTypes';

/**
 * applyOffer function
 * @function applyOffer
 * @param {string} url Content url.
 * @param {Object} Form data
 * @returns {Object} applyOffer action.
 */
export function applyOffer(url, data) {
  return {
    type: APPLY_OFFER,
    request: {
      op: 'post',
      path: `${url}/@apply-offer`,
      data: data,
    },
  };
}
