import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Container, Segment, Header, Card, Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';


const View = (props) => {
  const { data, mode, content } = props;
  const dispatch = useDispatch();
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')
  const lang = content?.language?.token;
  const offers = useSelector((state) => state.search.subrequests.offers?.items);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['Offer'],
      review_state: 'published',
      sort_on: 'effective',
      sort_order: 'reverse',
      metadata_fields: ['effective']
    };

    dispatch(
      searchContent(
        '/' + lang,
        contentFilter,
        'offers',
      ),
    );
  }, [dispatch]);

  return (
    <section className={'offersList ' + fullWidthClass + backgroundClass}>
      <Container>
        <Grid >
          {(data?.title || data?.description) && (
            <Grid.Row columns={12} centered>
              <Grid.Column mobile={12} tablet={10} computer={8} largeScreen={8} widescreen={8}>
                {data?.title && (<Header as="h2" className={"offersList__title " + (data?.description ? 'offersList__title--wDesc ' : 'offersList__title--noDesc ')}>{data.title}</Header>)}
                {data?.description && (<p className='offersList__desc'>{data.description}</p>)}
              </Grid.Column>
            </Grid.Row>
          )}
          {!isEmpty(offers) ? (
            <Grid.Row columns={12} className="offersList__cardGroup">
              {map(offers , (item) => (
                <Grid.Column key={item['@id']} mobile={12} tablet={6} computer={5} largeScreen={4} widescreen={4} >
                  <Card className='offersItem position-relative' fluid >
                    {item?.title && (
                      <Card.Header className="offersItem__title" as="p">
                        <UniversalLink
                          className="offersItem__link stretched-link" 
                          href={flattenToAppURL(item['@id'])}>{item.title}</UniversalLink>
                      </Card.Header>
                    )}
                    <Card.Content extra className="offersItem__readmore" aria-hidden="true">
                      <FormattedMessage  id="View offer" defaultMessage="View offer" aria-hidden="true" />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              ))}
            </Grid.Row>            
            ): (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <p className="mb-0 text-center">
                    <FormattedMessage id="There are no offers in this moment" defaultMessage="There are no offers in this moment" />
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}
        </Grid>

      </Container>
    </section>
  )
};

export default View;