/**
 * Header reducer.
 * @module reducers/header/header
 */

import { SET_HEADER_VISIBILITY } from '../../constants/ActionTypes';

const initialState = {
  showInnerHeader: true,
};

/**
 * Header reducer.
 * @function header
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function header(state = initialState, action = {}) {
  switch (action.type) {
    case SET_HEADER_VISIBILITY:
      return {
        ...state,
        showInnerHeader: action.value,
      }
    default:
      return state;
  }
}
