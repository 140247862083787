import { useState } from 'react';
import { Button, Segment, Header, Container} from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { keys, isEmpty, map } from 'lodash';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { FormattedMessage } from 'react-intl';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

const View = (props) => {
  const { data, mode } = props;
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : 'clients--editMode')
  const showLinks = false;

  // Inicialitza classe per para animació
  const [isAnimated, setAnimation] = useState(true);
  const toggleAnimation = () => {
    setAnimation(!isAnimated);
  };
 
  return (
    <section className={'clients ' + fullWidthClass + backgroundClass + (isAnimated ? ' clients--play' : ' clients--stop')}>
      <Segment basic >
          {data?.title && (<Header as="h2" size="tiny" className="clients__title ">{data.title}</Header>)}

          {!isEmpty(data?.images) && (
            <>
              <div className='clients__wrapper' style={{ '--ecc-logo-number': data.images.length }}>
                <div className='clients__track' >
                  {map(data.images , (item, index) => (
                    <React.Fragment key={item['@id']}>
                      {(item?.link && showLinks) ? (
                        <UniversalLink href={item.link} className='clientsItem' >
                        <img className='ui image'
                          src={
                            getScaleUrl(getFieldURL(item.image), 'preview') ||
                            DefaultImageSVG
                          }
                          alt={item.title ? item.title : ''}
                        />
                        </UniversalLink>
                      ) : (
                        <figure className='clientsItem' >
                          <img className='ui image'
                            src={
                              getScaleUrl(getFieldURL(item.image), 'preview') ||
                              DefaultImageSVG
                            }
                            alt={item.title ? item.title : ''}
                          />
                        </figure>
                      )}
                    </React.Fragment>
                  ))}
                  {(mode != 'edit') &&
                    (
                    <>
                    {map(data.images , (item) => (
                      <React.Fragment key={item['@id']}>
                        {(item?.link && showLinks) ? (
                          <UniversalLink href={item.link} className='clientsItem' aria-hidden="true" >
                          <img className='ui image'
                            src={
                              getScaleUrl(getFieldURL(item.image), 'preview') ||
                              DefaultImageSVG
                            }
                            alt={item.title ? item.title : ''}
                          />
                          </UniversalLink>
                        ) : (
                          <figure className='clientsItem clientsItem--clone'  aria-hidden="true">
                            <img className='ui image'
                              src={
                                getScaleUrl(getFieldURL(item.image), 'preview') ||
                                DefaultImageSVG
                              }
                              alt={item.title ? item.title : ''}
                            />
                          </figure>
                        )}
                      </React.Fragment>
                    ))}
                    </>
                    ) 
                  }
                  
                  
                </div>
              </div>

              {/* Toggle animation  */}
              { (mode != 'edit') && 
              <Container as="p" className="text-end mb-0 pt-3">
                <Button type="button" onClick={toggleAnimation} className="clients__pauseButton" >
                  { isAnimated ? (
                    <>
                      <FontAwesomeIcon icon={faPause}  />
                      <span className="visually-hidden"><FormattedMessage id="Pause animation" defaultMessage="Pause animation" /></span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPlay}  />
                      <span className="visually-hidden"><FormattedMessage id="Play animation" defaultMessage="Play animation" /></span>
                    </>
                  )}
                </Button>
              </Container>
              }
            </>
          )}

      </Segment>

    </section>
  )
};

export default View;