import { Container, Segment, Header, Card, Grid, List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';


const View = (props) => {
  const { data, mode } = props;
  const variantClass = ' ' + (data?.variant ? data.variant : 'variant-pointslisting-01')
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')
  const pointsColumns = {
    tablet: 7,
    computer: 8,
    largeScreen: 7,
    widescreen: 6,
    floated: 'right',
  }

  return (
    <section className={'pointsListing ' + fullWidthClass + backgroundClass + variantClass}>
      <Container >

          <Grid >
            <Grid.Row columns={12}>
              {(data?.image || data?.variant === 'variant-pointslisting-02' ) && (
                <Grid.Column mobile={12} tablet={5} computer={4} largeScreen={5} widescreen={5} className="mb-4 mb-md-0">
                  {data?.image && (
                    <img className='ui image fluid pointsListing__img rounded-3'
                      src={
                        getScaleUrl(getFieldURL(data.image), 'preview') ||
                        DefaultImageSVG
                      }
                      alt=''
                      srcSet={getScaleUrl(getFieldURL(data.image), 'preview') + " 400w, " + 
                              getScaleUrl(getFieldURL(data.image), 'large') + " 800w, " + 
                              getScaleUrl(getFieldURL(data.image), 'great') + " 1200w, " + 
                              getScaleUrl(getFieldURL(data.image), 'huge') + " 1600w"
                              }
                      sizes="(max-width: 62em) 100vw, 50vw"
                    />
                  )}
                  {data?.variant === 'variant-pointslisting-02' && (
                    <>
                    {data?.title && (<Header as="h2" className={"pointsListing__title fs-4" + (data?.description ? " " : " mb-5")}>{data.title}</Header>)}
                    {data?.description && (<p className='mb-5 pb-4'>{data.description}</p>)}
                    </>
                  )}
                </Grid.Column>
              )}

              <Grid.Column mobile={12} {...((data?.image || data?.variant === 'variant-pointslisting-02') ? pointsColumns : {})}>
                {data?.variant !== 'variant-pointslisting-02' && (
                  <>
                  {data?.title && (<Header as="h2" className={"pointsListing__title fs-4" + (data?.description ? " " : " mb-5")}>{data.title}</Header>)}
                  {data?.description && (<p className='mb-5 pb-4'>{data.description}</p>)}
                  </>
                )}
                
                {!isEmpty(data?.items) && (
                  <List className='eccList pt-3' as="ul">
                    {map(data.items , (item) => (
                      <List.Item key={item['@id']} as="li" className="eccList__item">
                        {item?.title && (<strong className='eccList__header'>{item.title}</strong>)}
                        {item?.description} 
                      </List.Item>
                    ))}
                  </List>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>

      </Container>
    </section>
  )
};

export default View;