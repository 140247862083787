import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  background: {
    id: 'background',
    defaultMessage: 'Background',
  },
  images: {
    id: 'Images',
    defaultMessage: 'Images',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  textLink: {
    id: 'Text Link',
    defaultMessage: 'Text Link',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
});


const imageSchema = (props) => ({
  title: 'Image',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['image', 'title', 'link'],
    },
  ],

  properties: {
    link: {
      title: props.intl.formatMessage(messages.link),
      widget: 'url',
    },
    image: {
      title: props.intl.formatMessage(messages.image),
      widget: 'attachedimage',
    },
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
  },

  required: ['image'],
});


export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'background', 'images'],
      },
    ],
    properties: {
      title: {
      title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      background: {
        title: props.intl.formatMessage(messages.background),
        choices: [
          ['bg-background-01', 'Background 1'],
          ['bg-background-02', 'Background 2'],
          ['bg-background-03', 'Background 3'],
        ],
      },
      images: {
        widget: 'object_list',
      title: props.intl.formatMessage(messages.images),
        description: 'Add Client references',
        schema: imageSchema(props),
      },
    },
  };
};

export default schema;