/**
 * Carousel component.
 * @module components/Carousel/Carousel
 */

import React, { useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { DotButton, useDotButton } from './CarouselDotButtons'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './CarouselArrowButtons' 
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  slideshowNav: {
    id: 'Slideshow navigation',
    defaultMessage: 'Slideshow navigation',
  }
});

const Carousel = (props) => {
  const { slides, ItemView, options } = props;
  const intl = useIntl()
  const autoplay = options?.autoplay ? Autoplay() : undefined;
  const buttons = options?.buttons ? options?.buttons : false
  const dots = options?.dots ? options?.dots : false
  
  const [emblaRef, emblaApi] = autoplay ? useEmblaCarousel(options, [autoplay]) : useEmblaCarousel(options)

  const onButtonClick = useCallback((emblaApi) => {
    const { autoplay } = emblaApi.plugins()
    if (!autoplay) return
    if (autoplay.options.stopOnInteraction !== false) autoplay.stop()
  }, [])

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onButtonClick
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi, onButtonClick)

  return (
    
      <div className={`${options?.customClass} slider-wrapper  `}>

        <div className=" slider__viewport" ref={emblaRef}>
          <div className=" slider__container"> 
            {slides.map((item, index) => (
              <div className="slider__slide" key={item?.['@id'] || index} >
                <ItemView item={item} />
              </div>
                ))}
          </div>
        </div>

        <div className="slideControls">         

          {dots ? (
          <nav className="slider__dots" aria-label={intl.formatMessage(messages.slideshowNav)}>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={'slider__dot'.concat(
                  index === selectedIndex ? ' slider__dot--selected' : ''
                )}
              ><span className="visually-hidden"><FormattedMessage id="Slide" defaultMessage="Slide"  /> {index + 1}</span></DotButton>
            ))}
          </nav>
          ): null}

          {buttons ? (
          <div className="slider__buttons">
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>
          ): null}

        </div>
       
      </div>
  )
}

export default Carousel
