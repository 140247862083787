import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { displayBanner } from 'volto-gdpr-privacy/actions';
import cookieSVG from 'volto-gdpr-privacy/components/ShowGdprBanner/user-lock-solid.svg';

import 'volto-gdpr-privacy/components/ShowGdprBanner/show-gdpr-banner.css';

const messages = defineMessages({
  buttonShowLabel: {
    id: 'volto-gdpr-privacy-buttonShowLabel',
    defaultMessage: 'Manage cookies',
  },
});

const ShowGdprBanner = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onClick = (e) => {
    e.preventDefault();
    dispatch(displayBanner(true, true));
  };

  return (
    <Popup
      trigger={<button onClick={onClick} className="gdpr-privacy-show-banner ">
                <img src={cookieSVG} alt={intl.formatMessage(messages.buttonShowLabel)} />
                <span className="visually-hidden"><FormattedMessage id="Manage cookies" defaultMessage="Manage cookies"  /></span>
              </button>}
      content={intl.formatMessage(messages.buttonShowLabel)}
      position='left center'
    />
  );
};

export default ShowGdprBanner;