import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Segment } from 'semantic-ui-react';
import React, { useState } from 'react';
import {v4} from 'uuid';

const View = (props) => {
  const { data } = props;
  const [activeIndices, setActiveIndices] = useState([]);

  const handleClick = (index) => {
    setActiveIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const AccordionId = v4();

  return (
    <>
      <Segment as="section" className="mb-5 px-0" basic>
          <h2>{data.title}</h2>
          <p>{data.description}</p>
          {data.items && data.items.length > 0 && (
            <Accordion className="faqList">
            {data.items.map((item, index) => (
              <React.Fragment  key={index}>
                {item.title && (
                  <Accordion.Title
                    active={activeIndices.includes(index)}
                    index={index}
                    onClick={() => handleClick(index)}
                    className="faqList__title"
                    as="h3"
                    aria-expanded={activeIndices.includes(index)}
                    aria-controls={ AccordionId + '-content-' + index }
                    id={ AccordionId + '-title-' + index }
                  >
                    {item.title}
                    <FontAwesomeIcon icon={faAngleDown}  className='faqList__icon ms-2'  />
                  </Accordion.Title>
                )}
                {item.text && (
                  <Accordion.Content active={activeIndices.includes(index)} className="faqList__content" id={ AccordionId + '-content-' + index } aria-labelledby={ AccordionId + '-title-' + index } role="region">
                    <p>{item.text}</p>
                  </Accordion.Content>
                )}
              </React.Fragment>
            ))}
            </Accordion>
          )}
      </Segment>
    </>
  );
};

export default View;