import header from './header/header';
import navigation from './navigation/navigation';
import applyOffer from './applyOffer/applyOffer';
import contact from './contact/contact';

const reducers = {
    header,
    navigation,
    applyOffer,
    contact,
};

export default reducers;