/**
 * ContactView component.
 * @module components/Contact/ContactView
 */

import React from 'react';
import { Container, Grid, Segment, Card, Header, Button } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { ContactForm } from './';
import { Image } from '@plone/volto/components';
import logoEcityclic  from './img/logo-ecityclic-c.svg';

const messages = defineMessages({
  titleLleida: {
    id: 'Mapa de situacio oficines Lleida',
    defaultMessage: 'Mapa de situació oficines Lleida',
  },
  titleMataro: {
    id: 'Mapa de situacio oficines Mataro',
    defaultMessage: 'Mapa de situació oficines Mataró',
  },
});

const ContactView = (props) => {
  const { content } = props;
  const intl = useIntl();

  return (
    <>

      <ContactForm {...props} />

      {/* Coordenades */}
      <div className="contactCoord bg-background-02 py-5">
        <Segment basic>
          <figure className="contactCoord__img">
            <Image src={logoEcityclic} alt="ecityclic" width="354" height="85" sizes="11rem" />
          </figure>
          <Grid centered>
            <Grid.Row columns={12}>
              <Grid.Column mobile={12} tablet={6} computer={6} largeScreen={4} widescreen={3}>
                <iframe
                  className='contactCoord__map rounded-4 mb-2'
                  title={intl.formatMessage(messages.titleLleida)}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11931.041528063415!2d0.611199!3d41.61769300000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a6e1f54c3d4e23%3A0xce4431c2b054b0da!2secityclic%20Oficina%20Central!5e0!3m2!1sca!2ses!4v1722929527627!5m2!1sca!2ses"
                  width="600"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
                  <address className='contactCoord__address text-end mb-5'>
                    <h2 className='fs-4'>Oficina Central</h2>
                    <p>Carrer Joc de la Bola, 11. 25003 (Lleida) <br />
                    <a href="tel:+34973931001">+34 973 93 10 01</a></p>
                  </address>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={6} computer={6} largeScreen={4} widescreen={3}>
                <iframe
                  className='contactCoord__map rounded-4 mb-2'
                  title={intl.formatMessage(messages.titleMataro)}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585.2729096391827!2d2.4307988898274404!3d41.52806100892103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4b4e5b403824f%3A0x7ec244bfa1db9e88!2sCarrer%20d&#39;Ernest%20Lluch%2C%2032%2C%2008302%20Matar%C3%B3%2C%20Barcelona!5e0!3m2!1sca!2ses!4v1722931212402!5m2!1sca!2ses"
                  width="600"
                  height="450"                  
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
                  <address className='contactCoord__address text-start'>
                    <h2 className='fs-4'>TecnoCampus Mataró</h2>
                    <p>Parc TecnoCampus Mataró, <br />
                    Carrer Ernest Lluch, 32. 08302 (Mataró, Barcelona) <br />
                    <a href="tel:+34973931001">+34 973 93 10 01</a></p>
                  </address>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>

      {hasBlocksData(content) ? (
        <Container classNameP="mb-5">
          <RenderBlocks {...props} />
        </Container>
      ): null}


    </>
  );
};


export default ContactView;
