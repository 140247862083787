/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { hasBlocksData, flattenHTMLToAppURL, BodyClass } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from '@plone/volto/components';
import { toPublicURL } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { RelatedNews } from './';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
  const Image = config.getComponent({ name: 'Image' }).component;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const url = toPublicURL(content['@id']);
  return (
    <>
    <BodyClass className="template-newsitem" />
    <Container id="page-document" className="view-wrapper newsitem-view">
      <p className='me-3 fs-xs text-uppercase opacity-75 mb-5'>
        <FormattedMessage id="Published the" defaultMessage="Published the"/>
        <FormattedDate date={content.effective} className="ms-2"
          format={{
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }}
        />
      </p>
      {hasBlocksData(content) && (
        <div className="pageBlocks ">
        <RenderBlocks content={content} />
        </div>
      )}

      <div className="published-wrapper documentActions mb-5">
        
        <aside className="share-wrapper me-auto" aria-labelledby='shareTitle'>
          <h2 id="shareTitle" className="share-wrapper__title"><FormattedMessage id="Share" defaultMessage="Share"/></h2>

          <a className="socialShare__link socialShare__link-facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} rel="noopener" target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            <span className="visually-hidden">Facebook
            <FormattedMessage id="Opens in new window" defaultMessage="Opens in new window"  /></span>
          </a>

          <a className="socialShare__link" href={`https://twitter.com/share?url=${url}`} rel="noopener" target="_blank"
            >
              <FontAwesomeIcon  icon={faXTwitter} />
            <span className="visually-hidden">Twitter
            <FormattedMessage id="Opens in new window" defaultMessage="Opens in new window"  /></span>
          </a>

          <a className="socialShare__link" href={`https://api.whatsapp.com/send?text=${url}`} rel="noopener" target="_blank"
            >
              <FontAwesomeIcon  icon={faWhatsapp} />
            <span className="visually-hidden">Whatsapp 
            <FormattedMessage id="Opens in new window" defaultMessage="Opens in new window"  /></span>
          </a>

          <a className="socialShare__link" href={`mailto:?subject=${content.title}&body=${url}`} rel="noopener" target="_blank">          
              <FontAwesomeIcon  icon={faEnvelope} />
            <span className="visually-hidden"><FormattedMessage id="Email" defaultMessage="Email"/> 
            <FormattedMessage id="Opens in new window" defaultMessage="Opens in new window"  /></span>
          </a>
        </aside>
      </div>


      {!isEmpty(content.newsitem_subjects) && (<RelatedNews {...content} />)}

    </Container>
    </>
  )
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
