import { Segment, Header, Button, List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';
import { UniversalLink, Image } from '@plone/volto/components';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';


const View = (props) => {
  const { data, mode } = props;
  const pointsColumns = {
    tablet: 7,
    computer: 8,
    largeScreen: 7,
    widescreen: 6,
    floated: 'right',
  }

  return (
    <Segment as="article" className={'featuredItem ' + ((data?.background) ? data.background : ' ') } basic>
      <div className="featuredItem__text">
        {data.preTitle && (<p className="featuredItem__intro">{data.preTitle}</p>)}
        {data.title && (<Header className="featuredItem__title" as="h2" >{data.title}</Header>)}
        {data?.description && (<p className='mb-5 pb-4'>{data.description}</p>)}
        
        {!isEmpty(data?.items) && (
          <List className='eccList' as="ul">
            {map(data.items , (item) => (
              <List.Item key={item['@id']} as="li" className="eccList__item">
                <strong className='eccList__header'>{item.title}</strong>
                {item?.description}
              </List.Item>
            ))}
          </List>
        )}

        {data?.link && (

          <UniversalLink href={data.link} className="ui button primary">
            {data.textLink ? (<span>{data.textLink}</span>) : (<><FormattedMessage id="Read more" defaultMessage="Read more" /> <span className="visually-hidden">{data.title}</span></>) }
          </UniversalLink>

        )}
      </div>

      { (data?.image || data?.logo) && ( 
        <div className="featuredItem__imgWrapper">
          {data?.image && (
            <Image className='featuredItem__img'
                src={
                  getScaleUrl(getFieldURL(data.image), 'preview') ||
                  DefaultImageSVG
                }
                srcSet={getScaleUrl(getFieldURL(data.image), 'preview') + ' 400w, ' + getScaleUrl(getFieldURL(data.image), 'large') + ' 800w, ' + getScaleUrl(getFieldURL(data.image), 'great') + ' 1200w, ' + getScaleUrl(getFieldURL(data.image), 'huge') + ' 1600w'}
                sizes="(max-width: 62em) 100vw, 50vw"
                alt=""
              />
          )}
          {data?.logo && (
            <img className='ui image featuredItem__logo'
                src={
                  getScaleUrl(getFieldURL(data.logo), 'preview') ||
                  DefaultImageSVG
                }
                alt=''
              />
          )}
        </div>
      )}
    
    </Segment>

  )
};

export default View;