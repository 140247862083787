/**
 * Project component.
 * @module components/Project/Project
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Container, Grid, Segment, Card, Header, Button } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const Project = (props) => {
  const { content } = props;

  return (
    <>
      {hasBlocksData(content) ? (
        <Container>
          <RenderBlocks {...props} />
        </Container>
      ): null}

    </>
  );
};


export default Project;
