/**
 * Featured component.
 * @module components/Frontpage/News
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Container, Grid, Card, Header, Label, Segment, Button} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';


const News = (props) => { 
  const { content } = props;
  const dispatch = useDispatch();
  const news = useSelector((state) => state.search.subrequests.news?.items);
  const vocabularySubjects = 'product.ecityclic_web.vocabularies.NewsitemSubjects';
  const itemsSubjects = useSelector(
    (state) =>
      state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
        ? state.vocabularies[vocabularySubjects].items
        : [],
    shallowEqual,
  );

  const getNewsItemSubject = function(Subject) {
    if (!itemsSubjects) {
      return Subject
    }
    for (let item of itemsSubjects) {
      if (item.value === Subject) return item.label
    }
    return Subject
  }

  const getNewsURL = function (content) {
    let path = '/' + content.id
    return flattenToAppURL(path)
  }
  const newsURL = getNewsURL(content)

  useEffect(() => {
    dispatch(
      searchContent(
        newsURL,
        {
          portal_type: ['News Item'],
          review_state: 'published',
          b_size: 3,
          sort_on: 'effective',
          sort_order: 'reverse',
          metadata_fields: ['effective', 'newsitem_subjects',  'hasPreviewImage']
        },
        'news',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects, lang: content.language.token}));
  }, [dispatch]);


  const mystyle = {
    "--ecc-subject-color": "tomato"
  };
  return !isEmpty(news) ? (
    <Container className="newsSection">
      <Segment as="section" basic className="px-0">
        <Header as="h2" className="fs-3 text-center justify-content-center newsSection__title ">
          <FormattedMessage id="News of ecityclic" defaultMessage="News of ecityclic"  />
        </Header>
        <Grid>
          <Grid.Row columns={12} className="justify-content-center">
            {news.map((item) => (
            <Grid.Column mobile={12} tablet={6} computer={4}  key={item['@id']} className="mb-4 mb-lg-0">
              <Card fluid className='newsItem position-relative' as="article">
                <div className="image newsItem__img">
                  <Image item={item} alt="" 
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 25rem"
                   />
                </div>
                <Card.Content >
                  <Card.Meta>
                    <FormattedDate date={item.effective} className="newsItem__date "
                      format={{
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }}
                    />
                    {item?.newsitem_subjects  ? (
                      <Label.Group className="subjects newsItem__subject">
                        {map(item.newsitem_subjects.slice(0, 1) , (item, index) => (
                          <Label className="newsItem__subject" key={index}>{getNewsItemSubject(item)}</Label>
                        ))}
                      </Label.Group>
                    ) : null}
                  </Card.Meta>
                  <Card.Header className="newsItem__title" as="h2">
                    <UniversalLink
                      className="newsItem__link newsItem__title stretched-link" 
                      href={flattenToAppURL(item['@id'])}>
                      {item.title}
                    </UniversalLink>
                  </Card.Header>
                  <span className="readMoreItem"  aria-hidden="true">
                    <FontAwesomeIcon className='readMoreItem__icon' icon={faCirclePlus} />
                    <FormattedMessage id="Read more" defaultMessage="Read more" />
                  </span>
                </Card.Content>
              </Card>
            </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  ) : (
    <></>
  );

}

export default News;
