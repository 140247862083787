/**
 * Header component.
 * @module components/Header/Header
 */

import { useState, useEffect } from 'react';
import { Segment, Button, Container, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl, useIntl } from 'react-intl';
import { faTextHeight, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons'; 
import { map } from 'lodash';
import { 
  LanguageSelector,
  Logo,
  Navigation,
  Breadcrumbs
} from '@plone/volto/components'; 
import { addAppURL, getView } from '@plone/volto/helpers'; 

import { HeaderContentInfo } from '../';

import { BodyClass } from '@plone/volto/helpers';


const messages = defineMessages({
  contrastBtn: {
    id: 'Change contrast',
    defaultMessage: 'Change contrast',
  },
  fontSizeBtn: {
    id: 'Modify font size',
    defaultMessage: 'Modify font size',
  },
  mnUtilities: {
    id: 'Utilities menu',
    defaultMessage: 'Utilities menu'
  }
});


const Header = ({ pathname, isCmsUI }) => {
  const intl = useIntl();
  const action = getView(pathname);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data);
  const { topActions = [] } = useSelector(
    (state) => ({
      topActions: state.actions?.actions?.top_actions,
    }),
    shallowEqual,
  );
  const showInnerHeader = useSelector((state) => {
    if (state?.content?.data?.['@type'] === 'LRF' && action === 'view') {
      return false
    }
    return state?.header?.showInnerHeader
  });
  const url = content ? addAppURL(content?.['@id'] || '/') : null;

  const [darkMode, setDarkMode] = useState(false);
  const [fontSizeMode, setFontSizeMode] = useState(false);

  const handleContrastClick = () => {
    setDarkMode(!darkMode);
  };

  const fontSwitch = () => {
    document.documentElement.style.fontSize = "24px";
    setFontSizeMode(!fontSizeMode); 
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedDarkMode = JSON.parse(localStorage.getItem('darkMode'), false);
      const storedFontSize = JSON.parse(localStorage.getItem('fontSizeMode'), false);
      setDarkMode(storedDarkMode)
      setFontSizeMode(storedFontSize)
    }

    // **********************************************************
    // Change header when the user scrolls the page
    var scrollDistance = function (callback, refresh) {
      // Make sure a valid callback was provided
      if (!callback || typeof callback !== 'function') return;
      // Variables
      var isScrolling, start, end, distance;
      start = 0;
      // Listen for scroll events
      window.addEventListener('scroll', function (event) {         
        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);
        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(function() {
          // Calculate distance
          end = window.scrollY;
          distance = end - start;
          // Run the callback
          callback(distance, start, end);
          // Reset calculations
          end = null;
          distance = null;
        }, refresh || 66);
      }, false);

    };
    // Add affix class on page load if necessary
    window.addEventListener('load', function(event) {
      if ( window.scrollY > (theHeader.offsetHeight + 16) ) {
        document.getElementById('portal-header').classList.add('affix');
      } else {
        document.getElementById('portal-header').classList.remove('affix');       
      }
    }); 
    var theHeader = document.getElementById('portal-header');
    scrollDistance(function (distance) {
      if ( distance > (theHeader.offsetHeight + 16) ) {
        document.getElementById('portal-header').classList.add('affix');
      } else {
        document.getElementById('portal-header').classList.remove('affix');       
      }
    });
      
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('darkMode', darkMode);
      localStorage.setItem('fontSizeMode', fontSizeMode );
    }

    if (darkMode) {
      document.body.classList.add('highContrast');
    } else {
      document.body.classList.remove('highContrast');
    }

    if (fontSizeMode) {
      document.documentElement.style.fontSize = '24px';
      document.getElementsByClassName('fontSwitch')[0].classList.add('selected');
    } else {
      document.documentElement.style.fontSize = '16px';
      document.getElementsByClassName('fontSwitch')[0].classList.remove('selected');
    }
    
  }, [darkMode, fontSizeMode]);


  return (
    <>
      <BodyClass className={darkMode ? 'highContrast': 'noContrast'} />
      <BodyClass className={fontSizeMode ? 'bigFont' : 'normalFont'} />  
      <Segment basic as="header"  
        id="portal-header"  
        className="portalHeader d-print-none" 
        data-spy="affix" 
        data-offset-top="205" 
        >
        <Container className="portalHeader__container">
          <Logo />
          <Navigation pathname={pathname} />
          <LanguageSelector />
        </Container>
        
      </Segment>
      <div className='affixFixer' role='presentation'></div>

      {/* Floating sidebar */}
      <Segment as="aside" id="sidebarActions" basic className="directActions d-print-none" aria-label={intl.formatMessage(messages.mnUtilities)}>
        <Popup
          trigger={<Button className="constrastToggle directActions__btn" onClick={handleContrastClick}>
                      <FontAwesomeIcon icon={faCircleHalfStroke} />
                      <span className="visually-hidden"><FormattedMessage id="Change contrast" defaultMessage="Change contrast"  /></span>
                    </Button>}
          content={intl.formatMessage(messages.contrastBtn)} 
          position='left center'
        />
        <Popup
          trigger={ <Button onClick={fontSwitch} className="directActions__btn fontSwitch ">
                      <FontAwesomeIcon icon={faTextHeight} />
                      <span className="visually-hidden"><FormattedMessage id="Modify font size" defaultMessage="Modify font size"  /></span>
                    </Button>}
          content={intl.formatMessage(messages.fontSizeBtn)}
          position='left center'
        />
      </Segment>
      
      
        { showInnerHeader && (
          <>
            <Breadcrumbs pathname={pathname} />
            <HeaderContentInfo pathname={pathname} isCmsUI={isCmsUI} />
          </>
        )}
      
    </>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};

export default injectIntl(Header);