/**
 * CarouselArrowButtons components.
 * @module components/Carousel/CarouselArrowButtons
 */

import React, { useCallback, useEffect, useState } from 'react'

export const usePrevNextButtons = (emblaApi, onButtonClick) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
    if (onButtonClick) onButtonClick(emblaApi)
  }, [emblaApi, onButtonClick])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
    if (onButtonClick) onButtonClick(emblaApi)
  }, [emblaApi, onButtonClick])

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

export const PrevButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="slider__button slider__button--prev"
      type="button"
      {...restProps}
    >
      <span className="visually-hidden">Previous</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 13 24" className="slider__button__svg" aria-hidden="true">
        <path d="M23.687.37A1.116,1.116,0,0,0,22.072.323L12,10.19,1.928.323A1.115,1.115,0,0,0,.313.37,1.21,1.21,0,0,0,.357,2.041L11.214,12.676a1.115,1.115,0,0,0,1.571,0L23.643,2.041A1.21,1.21,0,0,0,23.687.37Z" transform="translate(13) rotate(90)" fill="currentColor"/>
      </svg>
      {children}
    </button>
  )
}

export const NextButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="slider__button slider__button--next"
      type="button"
      {...restProps}
    >
      <span className="visually-hidden">Next</span>
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 24" aria-hidden="true" className="slider__button__svg">
              <path d="M.37.31c-.46.41-.5 1.12-.08 1.58.01.01.02.03.04.04L10.2 12 .32 22.07c-.43.44-.43 1.14 0 1.58l.04.04c.48.43 1.22.41 1.67-.04l10.63-10.86c.43-.43.43-1.14 0-1.57L2.04.36C1.59-.1.85-.12.37.31Z" fill="currentColor"/>          
      </svg>
      {children}
    </button>
  )
}
