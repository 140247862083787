import { useState, useEffect, useRef } from 'react';
import { Container, Segment, Header, Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { Icon} from '../../';
import {v4} from 'uuid';
 

const View = (props) => {
  const { data, mode } = props;
  const [isShown, setIsShown] = useState(0);
  const [intHeight, setInHeight] = useState(0);
  const [hasClassExpand, setHasClassExpand] = useState(false);
  const tabElement = useRef();   
 

  useEffect(() => { 
    if (!tabElement?.current) return;

    const resizeObserver = new ResizeObserver(() => {
      const height = tabElement.current.offsetHeight;
      document.documentElement.style.setProperty('--ecc-tab-height-min', height + 'px');
      setInHeight(height)
    });
    resizeObserver.observe(tabElement.current);
    return () => resizeObserver.disconnect();
  }, [])

  useEffect(() => {
    const tabsItems = document.querySelectorAll('.tabsItem__text__inner')
    tabsItems.forEach((element, index) => {
      if (index === isShown) {
        const height = element.offsetHeight;
        document.documentElement.style.setProperty('--ecc-tab-height-max', height + 'px');
        if (height < intHeight) {
          setHasClassExpand(index)
        } else {
          setHasClassExpand(false)
        }
      }
    });
  }, [isShown, intHeight])

  function handleShowItem(index) {
    setHasClassExpand(false)
    setIsShown(index);
  }

  function handleClickExpand(index) {
    setHasClassExpand(index);
  };


  const tabsId = v4();
  

  return (
    /* TODO: posar ids únics per a ARIA */
    <section id={tabsId} className={'full-width tabListing ' + ((data?.background && mode !== 'edit') ? data.background : ' ') + (mode === 'edit' ? 'editMode' : ' ')}>
      <Container className={ (data?.background && mode === 'edit') ? data.background : ' ' }>
          {data?.title && (<Header as="h2" id={tabsId + '-title'} className={'tabListing__title ' + (data?.description ? 'tabListing__title--wDesc' : 'tabListing__title--noDesc')} >{data.title}</Header>)}
          {data?.description && (<p className='tabListing__desc '>{data.description}</p>)}

          {!isEmpty(data?.items) && (
            <div className='eccTabs' >
              <div className='eccTabs__menu' ref={tabElement}  role='tablist' aria-orientation='vertical' aria-labelledby={tabsId + '-title'}>
                {map(data.items , (item, index) => (
                  <Button basic className={'eccTabs__menuItem' + (isShown === index ? ' selected' : ' ')}
                    key={item['@id'] + '-tab'}
                    type="button"
                    onClick={(e) => handleShowItem(index)}
                    role="tab"
                    aria-selected={isShown === index ? 'true' : 'false'}                    
                    aria-controls={tabsId + '-tabpanel-' + index}
                    >{item.title}</Button>
                ))}         
              </div>
              <div className='eccTabs__content'>
                {map(data.items , (item, index) => (
                  <article className={'eccTabs__contentItem tabsItem ' + (isShown === index ? ' visible' : ' ')} 
                    id={tabsId + '-tabpanel-' + index}
                    key={item['@id'] + '-article'}
                    role="tabpanel"
                    tabIndex="0"
                    >
                    <header className="tabsItem__header position-relative">
                      {item?.icon && (
                        <div className='greenIcon'>
                          <Icon name={item.icon} />
                        </div>
                      )}
                      <Header className="tabsItem__title" as="h3">
                        {(item?.link && mode !== 'edit') ? (
                          <UniversalLink href={item.link} className="tabsItem__link stretched-link">{item.title}</UniversalLink>
                        ) : (
                          <span>{item.title}</span>
                        )}
                      </Header>

                      {item?.link && (
                        <span className="tabsItem__readmore">
                          <FontAwesomeIcon className='tabsItem__readmore__icon' icon={faCirclePlus} />
                          {item.textLink ? (<span>{item.textLink}</span>) : (<FormattedMessage id="Know more" defaultMessage="Know more" />)}
                        </span>
                      )}

                    </header>
                    {item?.text?.data && (
                      <div className={'tabsItem__text' + (hasClassExpand === index  ? ' tabsItem__text--expanded ' : ' '  )} >
                        <div className='tabsItem__text__inner'
                          dangerouslySetInnerHTML={{
                            __html: flattenHTMLToAppURL(item.text.data),
                          }}
                        />

                        <p className="mb-0 tabsItem__text__expand">
                          <Button basic className="tabsItem__text__expand__btn" type="button" onClick={(e) => handleClickExpand(index)}>
                            <FontAwesomeIcon className='tabsItem__text__expand__btn__icon' icon={faCircleArrowDown} />
                            <FormattedMessage id="Read more" defaultMessage="Read more" />
                          </Button>
                        </p>
                      </div>
                    )}
                  </article>
                ))} 
              </div>
            </div>
          )}

      </Container>
    </section>
  )
};

export default View;