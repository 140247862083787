import { Container, Segment, Header, Card, Grid, List } from 'semantic-ui-react';
import { keys, isEmpty, map } from 'lodash';
import { Carousel } from '../../'; 

// CAROUSEL ITEM
const ItemView = props => {
  const {item} = props

  return (
    <Card as="article" className='slideCardsItem' key={item['@id']} >
      <Card.Content>
        <Card.Header as='h3' className="slideCardsItem__title">{item.title}</Card.Header>
        <p>{item.description}</p>
      </Card.Content>
    </Card> 
  )
};


const View = (props) => {
  const { data, mode } = props;
  const backgroundClass = ' ' + (data?.background ? data.background : '')
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')
  const SLIDE_OPTIONS = {
    loop: false,
    autoplay: false,
    dots: true,
    buttons: false,
    customClass: 'cards-slider',
    stopOnMouseEnter: true,
    slidesToScroll: 'auto'
  }

  return (
    <section className={'slideCards ' + fullWidthClass + backgroundClass}>
      <Container >
        <Grid className="">
          <Grid.Row columns={12}>
            <Grid.Column className="centered" mobile={12} tablet={10} computer={8} largeScreen={6}>
              {data?.title && (<Header as="h2" className={'slideCards__title' + (data?.description ? ' slideCards__title--wDesc ' : ' slideCards__title--noDesc ')}>{data.title}</Header>)}
              {data?.description && (<p className='slideCards__desc'>{data.description}</p>)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!isEmpty(data?.items) && (<Carousel slides={data.items.slice(0, 9)} ItemView={ItemView} options={SLIDE_OPTIONS} /> )}

      </Container>
    </section>
  );
};

export default View;
