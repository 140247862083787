/**
 * OfferForm component.
 * @module components/OfferForm/OfferForm
 */
import { useCallback, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { Container, Message, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Toast } from '@plone/volto/components';
import { getBaseUrl, usePrevious } from '@plone/volto/helpers';
import { applyOffer } from '../../actions';


const messages = defineMessages({
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  offerFormTitle: {
    id: 'Apply on this offer',
    defaultMessage: 'Apply on this offer',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  fullName: {
    id: 'Name and surnames',
    defaultMessage: 'Name and surnames',
  },
  fullNamePlaceholder: {
    id: 'Write here your name and surnames',
    defaultMessage: 'Write here your name and surnames',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'Write here your email',
    defaultMessage: 'Write here your email',
  },
  phone: {
    id: 'Mobile phone',
    defaultMessage: 'Mobile phone',
  },
  phonePlaceholder: {
    id: 'Write here your phone (optional)',
    defaultMessage: 'Write here your phone (optional)',
  },
  linkedin: {
    id: 'Linkedin link',
    defaultMessage: 'Linkedin link',
  },
  linkedinPlaceholder: {
    id: 'Write here the link of your profile (optional)',
    defaultMessage: 'Write here the link of your profile (optional)',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messagePlaceholder: {
    id: 'Tell us about yourself, your experience, why you think this vacancy is ideal for you, etc.',
    defaultMessage: 'Tell us about yourself, your experience, why you think this vacancy is ideal for you, etc.',
  },
  curriculum: {
    id: 'Curriculum',
    defaultMessage: 'Curriculum',
  },
  curriculumHelp1: {
    id: 'File must be less than 2 MB.',
    defaultMessage: 'File must be less than 2 MB.',
  },
  curriculumHelp2: {
    id: 'Type of files allowed: txt rtf pdf doc docx odt',
    defaultMessage: 'Type of files allowed: txt rtf pdf doc docx odt',
  },
  privacyPolicy: {
    id: 'I have read and accept the privacy policy',
    defaultMessage: 'I have read and accept the privacy policy',
  },
  recaptcha: {
    id: 'Recaptcha',
    defaultMessage: 'Recaptcha',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  sucessMessage: {
    id: 'Your application has been registered successfully',
    defaultMessage: 'Your application has been registered successfully',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  errorRecaptcha: {
    id: 'Error Recaptcha',
    defaultMessage: 'Error Recaptcha',
  },
});

const useApplyOffer = () => {
  const loading = useSelector((state) => state.applyOffer.loading);
  const loaded = useSelector((state) => state.applyOffer.loaded);
  const error = useSelector((state) => state.applyOffer.error);

  return { loading, loaded, error };
};

const OfferForm = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const language = useSelector((state) => state.intl.locale);

  const getCurriculumHelp = function (content) {
    let curriculumHelp = `
      <p>
        ${intl.formatMessage(messages.curriculumHelp1)}
      </p>
      <p>
        ${intl.formatMessage(messages.curriculumHelp2)}
      </p>
    `
    return curriculumHelp
  }
  const getPrivacyPolicyLink = function (content) {
    let privacyPolicyLink = `
      <p>
        Consultar
        <a href='/es/aviso-legal' target="_blank">Política de privacidad</a>.
      </p>
    `
    if (language === 'ca')
      privacyPolicyLink = `
        <p>
          Consultar
          <a href='/ca/avis-legal' target="_blank">Política de privacitat</a>.
        </p>
      `
    return privacyPolicyLink
  }
  const getPrivacyText = function (content) {
    let privacyText = `
      <p><strong>INFORMACIÓN SOBRE PROTECCIÓN DE DATOS</strong></p>
      <p>
        <strong>RESPONSABLE</strong>: ecityclic Solutions, SL, B10687861, PCITAL, Edificio H1 2 25003 Lleida. 
        <strong>FINALIDADES</strong>: Participar en procesos de selección de personal. Realizar perfiles de personalidad 
        y laborales. Verificar las referencias aportadas. 
        <strong>LEGITIMACIÓN</strong>: Consentimiento del candidato el enviar el CV espontáneamente. 
        Medidas precontractuales en caso de tratarse de una oferta de empleo. 
        <strong>DESTINATARIOS</strong>: No están previstas cesiones, salvo las legalmente obligatorias. 
        <strong>CONSERVACIÓN</strong>: Durante el desarrollo de los procesos de selección de personal 
        y, en el supuesto de haber enviado el CV espontáneamente, máximo durante 1 año para futuros procesos. 
        <strong>DERECHOS</strong>: Puede retirar su consentimiento en cualquier momento y ejercer su 
        derecho de acceso, rectificación, supresión, portabilidad de sus datos y de limitación dirigiéndose 
        a los datos del responsable, indicando el tratamiento específico y derecho que quiere ejercer. 
        En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos 
        (<a href="http://www.aepd.es" target="_blank" rel="noopener">www.aepd.es</a>).
      </p>
    `
    if (language === 'ca')
      privacyText = `
        <p><strong>INFORMACIÓ SOBRE PROTECCIÓ DE DADES</strong></p>
        <p>
          <strong>RESPONSABLE</strong>: ecityclic Solutions, SL, B10687861, PCITAL, Edifici H1 2 25003 Lleida. 
          <strong>FINALITATS </strong>: Participar en processos de selecció de personal. Realitzar perfils 
          de personalitat i laborals. Verificar les referències aportades. 
          <strong>LEGITIMACIÓ: </strong>Consentiment del candidat l'enviar el CV espontàniament. 
          Mesures precontractuals en cas de tractar-se d'una oferta d'ocupació. 
          <strong>DESTINATARIS</strong>: No estan previstes cessions, excepte les legalment obligatòries. 
          <strong>CONSERVACIÓ</strong>: Durant el desenvolupament dels processos de selecció de personal 
          i, en el supòsit d'haver enviat el CV espontàniament, màxim durant 1 any per a futurs processos. 
          <strong>DRETS</strong>: Pot retirar el seu consentiment en qualsevol moment i exercir el seu 
          dret d'accés, rectificació, supressió, portabilitat de les seves dades i de limitació dirigint-se 
          a les dades del responsable, indicant el tractament específic i dret que vol exercir. 
          En cas de divergències, pot presentar una reclamació davant l'Agència de Protecció de Dades 
          (<a href="http://www.aepd.es" target="_blank" rel="noopener">www.aepd.es</a>)
        </p>
      `
    return privacyText
  }

  const privacyText = getPrivacyText()
  const privacyPolicyLink = getPrivacyPolicyLink()
  const curriculumHelp = getCurriculumHelp()

  const captchaRef = useRef();
  const captchaToken = useRef();

  const { loaded, loading, error } = useApplyOffer();
  const prevloading = usePrevious(loading);

  useEffect(() => {
    if (prevloading && loaded) {
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.sucessMessage)}
        />,
      );
    }
  }, [intl, loaded, prevloading]);

  const onSubmit = (data) => {
    captchaRef.current
      .verify()
      .then(() => {
        dispatch(applyOffer(getBaseUrl(pathname), {...data, recaptcha: captchaToken.current}));
      })
      .catch(() => {
        toast.error(
          <Toast
            error
            title={intl.formatMessage(messages.errorRecaptcha)}
            content={error.errorRecaptcha}
          />,
        );
      });
  };

  return (
    <div id="offer-form" className='offerForm full-width bg-background-02'>
 
        {error && (
          <Message
            icon="warning"
            negative
            attached
            header={intl.formatMessage(messages.error)}
            content={error.message}
          />
        )}
        <Form
          id='form'
          onSubmit={onSubmit}
          formData={{ blocksLayoutFieldname: {} }}
          submitLabel={intl.formatMessage(messages.send)}
          resetAfterSubmit
          title={intl.formatMessage(messages.offerFormTitle)}
          loading={loading}
          schema={{
            fieldsets: [
              {
                fields: [
                  'fullName', 'email', 'phone', 'linkedin', 'message',
                  'curriculum', 'curriculumHelp', 'privacyText', 'privacyPolicy',
                  'privacyPolicyLink', 'recaptcha'
                ],
                id: 'default',
                title: intl.formatMessage(messages.default),
              },
            ],
            properties: {
              fullName: {
                title: intl.formatMessage(messages.fullName),
                placeholder: intl.formatMessage(messages.fullNamePlaceholder),
                type: 'string',
              },
              email: {
                title: intl.formatMessage(messages.email),
                placeholder: intl.formatMessage(messages.emailPlaceholder),
                type: 'email',
              },
              phone: {
                title: intl.formatMessage(messages.phone),
                placeholder: intl.formatMessage(messages.phonePlaceholder),
                type: 'string',
              },
              linkedin: {
                title: intl.formatMessage(messages.linkedin),
                placeholder: intl.formatMessage(messages.linkedinPlaceholder),
                type: 'string',
              },
              message: {
                title: intl.formatMessage(messages.message),
                placeholder: intl.formatMessage(messages.messagePlaceholder),
                type: 'string',
                widget: 'textarea',
              },
              curriculum: {
                title: intl.formatMessage(messages.curriculum),
                widget: 'file_custom_widget',
              },
              curriculumHelp: {
                widget: 'html_label_widget',
                html: curriculumHelp,
              },
              privacyText: {
                widget: 'html_label_widget',
                html: privacyText,
              },
              privacyPolicy: {
                title: intl.formatMessage(messages.privacyPolicy),
                widget: 'checkbox_widget',
                required: true,
              },
              privacyPolicyLink: {
                widget: 'html_label_widget',
                html: privacyPolicyLink,
              },
              recaptcha: {
                title: intl.formatMessage(messages.recaptcha),
                widget: 'google_recaptcha_widget',
                captchaRef: captchaRef,
                captchaToken: captchaToken,
              }, 
            },
            required: ['fullName', 'email', 'message', 'privacyPolicy'],
          }}
        />
      
    </div>
  );
};

export default OfferForm;
