import { defineMessages } from 'react-intl';

const messages = defineMessages({
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  background: {
    id: 'background',
    defaultMessage: 'Background',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  cards: {
    id: 'Cards',
    defaultMessage: 'Cards',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  }
});
const cardSchema = (props) => ({
  title: 'Cards',
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'icon'],
    },
  ],

  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'textarea',
    },
    icon: {
      title: props.intl.formatMessage(messages.icon),
      widget: 'icon_selector',
    },
  },

  required: ['title'],
});


const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'background', 'description', 'cards'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      background: {
        title: props.intl.formatMessage(messages.background),
        choices: [
          ['bg-background-01', 'Background 1'],
          ['bg-background-02', 'Background 2'],
          ['bg-background-03', 'Background 3'],
        ],
      },
      description: {
        title: props.intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      cards: {
        title: props.intl.formatMessage(messages.cards),
        description: 'Add Cards',
        schema: cardSchema(props),
        widget: 'object_list',
      },
    },
  };
};

export default schema;