import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  background: {
    id: 'background',
    defaultMessage: 'Background',
  },
  grid: {
    id: 'Grid',
    defaultMessage: 'Grid',
  },
});

export const GridBlockSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.grid),
    block: 'grid',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline', 'background'],
      },
    ],

    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },
      background: {
        title: props.intl.formatMessage(messages.background),
        choices: [
          ['bg-background-01', 'Background 1'],
          ['bg-background-02', 'Background 2'],
          ['bg-background-03', 'Background 3'],
        ],
      },
    },
    required: [],
  };
};
