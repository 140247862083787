/**
 * Header actions.
 * @module actions/hear/hear
 */

import { SET_HEADER_VISIBILITY } from '../../constants/ActionTypes';


/**
 * Set header visibility function.
 * @function setHeaderVisibility
 * @param {Object} Header info.
 * @returns {Object} Header action.
 */
export function setHeaderVisibility(value) {
  return {
    type: SET_HEADER_VISIBILITY,
    value,
  };
}
