import React, { useEffect, useCallback, createRef } from 'react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { useIntl } from 'react-intl';
import { GoogleInnerReCaptchaWidget } from './'

const sitekey = '6LfIH6ApAAAAAFIRR9Zw21AvCikb7XxorN5GYs1Y'


class GoogleReCaptchaWidget extends React.Component {
  constructor(props) {
    super(props);
  }

  reset() {
    const { captchaRef, captchaToken } = this.props;
  }

  verify() {
    const { captchaRef, captchaToken } = this.props;
    return captchaRef.current.verify();
    return new Promise((resolve) => {
      resolve();
    });
  }

  render() {
    const { captchaRef, captchaToken } = this.props;
    const errorMessage = false

    const RenderErrorMessage = () =>
      errorMessage ? (
        <div className="captcha-error-message">{errorMessage}</div>
      ) : (
        <></>
      );

    return (
      <>
        <GoogleInnerReCaptchaWidget
          captchaRef={captchaRef}
          captchaToken={captchaToken}
          sitekey={sitekey}
        ></GoogleInnerReCaptchaWidget>
        <RenderErrorMessage />
      </>
    );
  }
}

export default GoogleReCaptchaWidget;
