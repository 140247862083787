import { defineMessages } from 'react-intl';

const messages = defineMessages({
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
});

export const IconSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.icon),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['content_icon', 'title', 'url', 'description'],
      },
    ],
    properties: {
      content_icon: {
        title: props.intl.formatMessage(messages.icon),
        widget: 'string',
      },
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'string',
      },
      url: {
        title: props.intl.formatMessage(messages.url),
        widget: 'url',
      },
      description: {
        title: props.intl.formatMessage(messages.description),
        widget: 'string',
      },
    },
    required: ['content_icon'],
  };
};
