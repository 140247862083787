/**
 * Blog2 View component.
 * @module components/View/Blog2View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container as SemanticContainer, Card } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Icon} from '../';

const Blog2View = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  return (
    <>
      <BodyClass className='blogView'/>

      <Container className="blogView__list">
        <Grid className="pb-5">
          <Grid.Row columns={12} className="justify-content-center pb-4"  >
            {content.items.map((item) => (
              <Grid.Column key={item.url} mobile={6} tablet={6} computer={3} className="mb-4">
                <Card 
                  as="article"
                  className='iconItem position-relative'
                  fluid
                  >
                  <Card.Content className="iconItem__heading">
                    {item.content_icon && (
                      <Icon name={item.content_icon} className="iconItem__icon" />
                    )}
                    <Card.Header as="h2" className="blog2Item__title">
                      <UniversalLink item={item} className="blog2Item__link stretched-link">{item.title}</UniversalLink>
                    </Card.Header>
                  </Card.Content>
                  <Card.Content extra>
                    {item.description && 
                      <p className="iconItem__description mb-0">{item.description}</p>}
                    {item?.link &&
                      <span className="readMoreItem" aria-hidden="true">
                        <FontAwesomeIcon className='readMoreItem__icon' icon={faCirclePlus} />
                        <FormattedMessage id="Read more" defaultMessage="Read more" />
                      </span>
                    }
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};


export default Blog2View;
