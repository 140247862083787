import React from 'react';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const ItemSchema = {
    title: 'Term-Entry',
    properties: {
        term_id: {
            title: 'ID',
        },
        title_ca: {
            title: 'Title CA',
        },
        title_es: {
            title: 'Title ES',
        },
    },
    fieldsets: [
        {
            id: 'default',
            title: 'Term-Entry',
            fields: [
                'term_id',
                'title_ca',
                'title_es',
            ],
        },
    ],
    required: ['term_id', 'title_ca', 'title_es'],
};

const MultilingualTermWidget = (props) => {
    return (
        <ObjectListWidget
            schema={ItemSchema}
            {...props}
            value={props.value?.items || props.default?.items || []}
            onChange={(id, value) => props.onChange(id, { items: value })}
        />
    );
};

export default MultilingualTermWidget;