import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor'

const NumberAnimation = ({n}) => {
  const Number = ({ n }) => {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 200,
      config: { mass: 1, tension: 20, friction: 10 },
      // opacity: isVisible ? 1 : 0,
      // transform: isVisible ? "translateY(0)" : "translateY(50px)",
    });
    return <animated.span className="animatedNumber">{number.to((n) => n.toFixed(0))}</animated.span>;
  };
  const [isVisible, setIsVisible] = useState(false);
  const onChange = visibility => {
    visibility && setIsVisible(visibility);
  };
  return(
    <VisibilitySensor onChange={onChange} partialVisibility>
      <Number isVisible={isVisible} n={n} className="numberAnimation"/>
    </VisibilitySensor>
  )
}
export default NumberAnimation;